import PhoneNumberInput, {PhoneNumberData} from "../../../components/phoneNumberInput/phoneNumberInput";
import {useState} from "react";

interface ForwardCallProps {
    isValid: Function;
    type: string;
    msisdn: number | null | undefined;
}

const ForwardCall = ({isValid ,msisdn, type}: ForwardCallProps) => {
    const [phoneNumber, setPhoneNumber] = useState<string>(msisdn ? msisdn.toString() : '');
    const handleChange = (val: string) => {
        if (isValid(val, type)) {
            setPhoneNumber(val);
        }
    }
    return (
        <div className='mb-8'>
            <div className='text-sm text-gray-500 mt-2 mb-5'>
                All incoming calls will be forwarded to this number.
            </div>
            <PhoneNumberInput label='Phone number' phoneNumber={phoneNumber}
                              onNumberChange={(val: PhoneNumberData) => handleChange(val.phone)}
                             />
        </div>

    )
}
export default ForwardCall;
