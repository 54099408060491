import React, { useState, useRef, useEffect } from 'react';

interface AudioPlayerProps {
    src: string;
    disabled?: boolean;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ src }) => {
    const [hideIcon, setHideIcon] = useState(false);
    const [volumeStatus, setVolumeStatus] = useState(true);
    const [loading, setLoading] = useState(false);
    const [audioLength, setAudioLength] = useState<string | null>(null);
    const [audioCurrentMinutes, setAudioCurrentMinutes] = useState('00');
    const [audioCurrentSeconds, setAudioCurrentSeconds] = useState('00');
    const [volumeValueOld, setVolumeValueOld] = useState(0.5);

    useEffect(() => {
        rewind();
    }, [src]);

    const audioRef = useRef<HTMLAudioElement>(null);
    const trackLineRef = useRef<HTMLInputElement>(null);
    const volumeLineRef = useRef<HTMLInputElement>(null);

    const play = () => {
        if (audioRef.current) {
            if (audioRef.current.currentTime === audioRef.current.duration) {
                endAudio();
            }
            audioRef.current.play();
            setHideIcon(true);
            setAudioLength(formatTime(audioRef.current.duration));
            if (trackLineRef.current) {
                trackLineRef.current.max = audioRef.current.duration.toString();
            }
            if (audioRef.current.seekable.length === 0) {
                setLoading(true);
            }
        }
    };

    const pause = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            updateAudio();
            setHideIcon(false);
        }
    };

    const rewind = () => {
        if (trackLineRef.current) {
            trackLineRef.current.value = (parseFloat(trackLineRef.current.value) - 5).toString();
            updateCurrentTime();
        }
    };

    const forward = () => {
        if (trackLineRef.current) {
            trackLineRef.current.value = (parseFloat(trackLineRef.current.value) + 5).toString();
            updateCurrentTime();
        }
    };

    const updateAudio = () => {
        if (audioRef.current && trackLineRef.current) {
            if (!loading && audioRef.current.seekable.length === 0) {
                setLoading(true);
            } else if (audioRef.current.seekable.length !== 0) {
                setLoading(false);
            }

            if (Math.abs(parseFloat(trackLineRef.current.value) - audioRef.current.currentTime) > 0.7) {
                audioRef.current.currentTime = parseFloat(trackLineRef.current.value);
            } else {
                trackLineRef.current.value = audioRef.current.currentTime.toString();
            }
            trackLineRef.current.max = audioRef.current.duration.toString();
            setAudioLength(formatTime(audioRef.current.duration));
            setAudioCurrentMinutes(formatMinutes(audioRef.current.currentTime));
            setAudioCurrentSeconds(formatSeconds(audioRef.current.currentTime));
        }
    };

    const updateCurrentTime = () => {
        updateAudio();
    };

    const formatTime = (timeLength: number): string => {
        if (!timeLength) return '00:00';
        const minutes = Math.floor(timeLength / 60);
        const seconds = Math.floor(timeLength % 60);
        return `${minutes >= 10 ? minutes : '0' + minutes}:${seconds >= 10 ? seconds : '0' + seconds}`;
    };

    const formatMinutes = (timeLength: number): string => {
        if (!timeLength) return '00';
        const minutes = Math.floor(timeLength / 60);
        return minutes >= 10 ? minutes.toString() : '0' + minutes;
    };

    const formatSeconds = (timeLength: number): string => {
        if (!timeLength) return '00';
        const seconds = Math.floor(timeLength % 60);
        return seconds >= 10 ? seconds.toString() : '0' + seconds;
    };

    const endAudio = () => {
        pause();
        if (audioRef.current && trackLineRef.current) {
            audioRef.current.currentTime = 0;
            trackLineRef.current.value = audioRef.current.currentTime.toString();
        }
    };

    const changeVolume = () => {
        if (volumeLineRef.current && audioRef.current) {
            setVolumeStatus(parseFloat(volumeLineRef.current.value) > 0);
            audioRef.current.volume = parseFloat(volumeLineRef.current.value);
        }
    };

    const volumeOffFunc = () => {
        if (volumeLineRef.current && audioRef.current) {
            setVolumeValueOld(parseFloat(volumeLineRef.current.value));
            volumeLineRef.current.value = '0';
            audioRef.current.volume = 0;
            setVolumeStatus(false);
        }
    };

    const volumeOnFunc = () => {
        if (volumeLineRef.current && audioRef.current) {
            audioRef.current.volume = volumeValueOld;
            volumeLineRef.current.value = volumeValueOld.toString();
            setVolumeStatus(true);
        }
    };

    const getAudioLink = (): string => {
        console.log('audioRef.current?.src', audioRef.current?.src);
        return audioRef.current?.src ? audioRef.current.src : '';
    };

    const getAudioName = (): string => {
        return 'audio.wav';
    };

    return (
        src &&
        <div className={`player - wrapper p-3 bg-white border border-black`} >
            <div className="player-content text-gray-900">
                <div className="timeline">
                    <input
                        ref={trackLineRef}
                        type="range"
                        min="0"
                        max="100"
                        step="0.000001"
                        className="timeline-input w-full h-1 bg-black appearance-none"
                    />
                </div>
                <div className="controls-wrapper flex justify-between items-center mt-[6px]">
                    <div className="controls flex space-x-4">
                        <i onClick={rewind} className="material-icons player-icon cursor-pointer">fast_rewind</i>
                        <i
                            onClick={pause}
                            className={`material-icons player-icon cursor-pointer ${!hideIcon ? 'hidden' : ''}`}
                        >
                            pause
                        </i>
                        <i
                            onClick={play}
                            className={`material-icons player-icon cursor-pointer ${hideIcon ? 'hidden' : ''}`}
                        >
                            play_arrow
                        </i>
                        <i onClick={forward} className="material-icons player-icon cursor-pointer">fast_forward</i>
                    </div>
                    <div className="time text-gray-600">
                        <span className={`${loading ? 'hidden' : ''}`}>
                            <span>{audioCurrentMinutes}</span>:<span>{audioCurrentSeconds}</span>/<span>{audioLength}</span>
                        </span>
                        <div className={`${loading ? '' : 'hidden'} player-spinner`}></div>
                    </div>
                    <div className="volume flex items-center space-x-2">
                        <i
                            onClick={volumeOffFunc}
                            className={`material-icons player-icon cursor-pointer ${volumeStatus ? '' : 'hidden'}`}
                        >
                            volume_up
                        </i>
                        <i
                            onClick={volumeOnFunc}
                            className={`material-icons player-icon cursor-pointer ${!volumeStatus ? '' : 'hidden'}`}
                        >
                            volume_off
                        </i>
                        <input
                            ref={volumeLineRef}
                            type="range"
                            min="0"
                            max="1"
                            step="0.01"
                            className="timeline-input volume w-24 h-1 bg-black focus:bg-gradient-to-r focus:from-black focus:to-black appearance-none"
                            onChange={changeVolume}
                        />
                        <a href={getAudioLink()} download={getAudioName()} className="audio-download">
                            <i className="material-icons player-icon cursor-pointer">file_download</i>
                        </a>
                    </div>
                </div>
            </div>
            <audio ref={audioRef} src={src} onTimeUpdate={updateAudio} onEnded={endAudio} className="hidden" />
        </div>
    );
};

export default AudioPlayer;
