export  const removeEmptyFields = (data: any) => {
    const cleanedData: any = {};
    Object.keys(data).forEach(key => {
        if (Array.isArray(data[key])) {
            cleanedData[key] = data[key].filter((item: any) => item !== '' && item !== null && item !== undefined);
        } else if (typeof data[key] === 'object' && data[key] !== null) {
            cleanedData[key] = removeEmptyFields(data[key]);
        } else if (data[key] !== '' && data[key] !== null && data[key] !== undefined) {
            cleanedData[key] = data[key];
        }
    });
    return cleanedData;
};
