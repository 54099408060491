import {getFormatedPhoneNumber} from "../../utilis/msisdnUtilis";
import {useAppDispatch} from "../../store/store";
import {setPhoneNumber, setSelectedPrefix} from "../../store/features/calls/dialerSlice";
import {parsePhoneNumber} from "libphonenumber-js";
import {Icon} from "ch3-ui-lib";

interface ClickToCallProps {
    label?: string | undefined,
    msisdn: number
    noPrefix?: boolean
    className?: string
    icon?: boolean
}

export const ClickToCall = ({label, msisdn, noPrefix, className, icon=false}:ClickToCallProps) => {

    const call = (e:any) => {
        {
            const phoneNumberParsed = parsePhoneNumber(`+${msisdn.toString()}`);

            if (phoneNumberParsed) {
                const {country, countryCallingCode, number} = phoneNumberParsed;
                dispatch(setPhoneNumber(number));
                if (countryCallingCode) {
                    dispatch(
                        setSelectedPrefix({
                            isoCountry: country ?? 'US',
                            prefix: `+${countryCallingCode}`,
                        }),
                    );
                }
                e.preventDefault()
                e.stopPropagation()
            }

            e.preventDefault()
            e.stopPropagation();
        }
    }

    const dispatch = useAppDispatch();
    return (
        <div  onClick={(e:any)=> call(e)} className={`flex items-center hover:text-primary-500 cursor-pointer ${className}`}>{label ? label : getFormatedPhoneNumber(msisdn, noPrefix)}
            <div>
                {icon && <Icon className=' hover:text-primary-500 cursor-pointer' iconName={'call'} size='sm'/>}
        </div> </div>
    )
}
