import {useKeycloak} from "@react-keycloak/web";
import React, {useEffect, useState} from "react";
import {
    BrowserRouter,
    Route,
    Routes,
    useLocation,
} from "react-router-dom";
import ChSidebar from "./components/sidebar/Sidebar.tsx";
import {logo, translateSections} from "./utilis/menuItems.tsx";
import Calls from "./pages/Calls/Calls.tsx";
import ContactsList from "./pages/Contacts/Contacts.tsx";
import AddEditContact from "./pages/Contacts/AddEditContact.tsx";
import ContactDetails from "./pages/Contacts/ContactDetails.tsx";
import TeamList from "./pages/Team/TeamList.tsx";
import GroupList from "./pages/Groups/GroupList.tsx";
import GroupDetails from "./pages/Groups/GroupDetails.tsx";
import {Provider, useDispatch, useSelector} from "react-redux";
import {getIsDialerHidden, setIsInCallsAction, toggleDialerHidden} from "./store/features/calls/dialerSlice.ts";
import {store, useAppDispatch} from "./store/store.ts";
import {jwtDecode} from "jwt-decode";
import {DialerWrapper} from "./components/Dialer/DialerWrapper";
import CallDetails from "./pages/Calls/CallDetails";
import {fetchCurrentUser} from "./store/features/Users/usersSlice";
import PhoneNumberSettings from "./pages/PhoneNumbers/PhoneNumberSettings";
import {PhoneNumbers} from "./pages/PhoneNumbers/PhoneNumbers";
import {sipUserAgent} from "./components/Dialer/services/sipService";

import { ThemeProvider } from 'next-themes';
import ProfileSettings from "./pages/ProfileSettings/ProfileSettings.tsx";

import useMediaQuery from "./utilis/screenWidthUtils.ts";
import ToastNotification from "./components/Notifications/Notifications";


function App() {
    const dispatch = useAppDispatch();

    const [isInCalls, setIsInCalls] = useState(false);
    const [isAuthSaved, setIsAuthSaved] = useState(false);
    const isMobile = useMediaQuery('(max-width: 960px)');

    const {keycloak, initialized} = useKeycloak();

    useEffect(() => {
        if (keycloak.authenticated && initialized) {
            console.log("Authenticated");
            const decodedToken: { tenant: string, userId: string, given_name: string, family_name: string } = jwtDecode(keycloak.token!);
            localStorage.setItem("tenant", decodedToken.tenant);
            localStorage.setItem("userId", decodedToken.userId);
            localStorage.setItem("token", `${keycloak.token}`);

            console.log('decoded token', decodedToken);
            setIsAuthSaved(true)
            dispatch(fetchCurrentUser());

        }
        keycloak.onAuthRefreshSuccess = () => {
            console.log("Auth refresh success");
            const decodedToken: { tenant: string, userId: string } = jwtDecode(keycloak.token!);
            console.log('decoded token', decodedToken);
            localStorage.setItem("token", `${keycloak.token}`);
            sipUserAgent?.set('authorization_jwt', keycloak.token);
        }

        keycloak.onAuthLogout = () => {
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            localStorage.removeItem("tenant");
        }
    }, [keycloak, initialized]);

    if (!initialized) {
        return <div className="h-screen flex justify-center items-center">
                    <div
                className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
                    </div>
                </div>;
    }

    if (!keycloak.authenticated) {
        return <div>Not authenticated</div>;
    }

    if (!isAuthSaved) {
        return <div>Saving auth...</div>;
    }

    return (
        <>
        <ThemeProvider attribute="class" defaultTheme="light">
            <Provider store={store}>
                <BrowserRouter>
                    <React.StrictMode>
                        <div className="flex">
                            <ChSidebar
                                logo={logo}
                                sections={translateSections()}
                            />
                            <MainContentWrapper
                                className={`w-full flex flex-1` + `${isMobile ? ' ml-[80px]' : ' ml-[272px]'}`}
                                setIsInCalls={setIsInCalls}
                            >
                                <Routes>
                                    <Route path="/" element={<Calls/>}/>
                                    <Route path="/calls" element={<Calls/>}/>
                                    <Route path="/calls/details/:id" element={<CallDetails/>}/>
                                    <Route path="/contacts" element={<ContactsList/>}/>
                                    <Route path="/contacts/:id" element={<AddEditContact/>}/>
                                    <Route path="/contacts/new" element={<AddEditContact/>}/>
                                    <Route
                                        path="/contacts/details/:id"
                                        element={<ContactDetails/>}
                                    />
                                    <Route path="/team" element={<TeamList/>}/>
                                    <Route path="/groups" element={<GroupList/>}/>
                                    <Route
                                        path="/groups/details/:id"
                                        element={<GroupDetails/>}
                                    />
                                    <Route
                                        path="/groups/details/new"
                                        element={<GroupDetails/>}
                                    />
                                    <Route
                                        path="/phoneNumbers"
                                        element={<PhoneNumbers/>}
                                    />
                                    <Route
                                        path="/phoneNumbers/:id"
                                        element={<PhoneNumberSettings/>}
                                    />
                                    <Route
                                        path="/profile/:id"
                                        element={<ProfileSettings/>}
                                    />
                                </Routes>
                            </MainContentWrapper>
                        </div>
                    </React.StrictMode>
                </BrowserRouter>

                <DialerWrapper isInCalls={isInCalls}/>
            </Provider>
            <ToastNotification/>
            </ThemeProvider>
        </>
    );
}

function MainContentWrapper({
                                children,
                                setIsInCalls,
                                className,
                            }: {
    children: React.ReactNode;
    setIsInCalls: React.Dispatch<React.SetStateAction<boolean>>;
    className: string;
}) {
    const dispatch = useDispatch();
    const location = useLocation();

    const isDialerHidden = useSelector(getIsDialerHidden);

    React.useEffect(() => {
        const isCallsPath = location.pathname.includes('calls');

        if (isCallsPath && isDialerHidden) {
            dispatch(toggleDialerHidden());
        }
        dispatch(setIsInCallsAction(isCallsPath));
        setIsInCalls(isCallsPath);
    }, [location]);

    return <div className={className}>{children}</div>;
}

export default App;
