import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {ChToast} from "ch3-ui-lib";
import {getToast, hideToast} from "../../store/features/Notifications/notificationsSlice";

const ToastNotification = () => {
    const dispatch = useDispatch();
    const { title, description, isOpen, type } = useSelector(getToast);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                dispatch(hideToast());
            }, 3000); // Auto-hide toast after 3 seconds
        }
    }, [isOpen, dispatch]);

    return (
       <ChToast title={title} description={description} type={type} open={isOpen} setOpen={() =>console.log('test')} />
    );
};

export default ToastNotification;
