import { Icon } from 'ch3-ui-lib';
import { FormattedMessage } from 'react-intl';
import {getFlag, getFormatedPhoneNumber} from '../../utilis/msisdnUtilis';
import {duration, getTimeAgo} from '../../utilis/timeUtilis';
import {useState} from "react";
import {Call} from "../../store/features/calls/callsSlice";
import {getCallType} from "../../utilis/callsUtilis";
import {ClickToCall} from "../../components/ClickToCall/ClickToCall";
import { useTheme } from "next-themes";
import {FlagImage} from "react-international-phone";
import {hexToEmoji} from "../../components/Emoji/emoji";


interface RecentCallsProps {
    groupedCalls: { [key: string]: Call[] };
}

const RecentCallsDetails = ({ groupedCalls }: RecentCallsProps) => {

    const [selectedCallId, setSelectedCallId] = useState<number | null>(null);
    const { theme } = useTheme();

    const handleCallClick = (callId: number) => {
        setSelectedCallId(callId == selectedCallId ? null : callId);
    };

     function getCallDescription(call: Call): any {
        if (call.forwardedTo) {
            return 'Call forwarded to ' + getFormatedPhoneNumber(call.forwardedTo, true);
        }
        if (call.type === 'OUTBOUND') {
            return 'Outgoing call';
        }
        if (call.type === 'INBOUND' && !call.bridgetAt) {
            return 'Missed call';
        }
        if (call.type === 'INBOUND' && call.bridgetAt) {
            return 'Incoming call';
        }
        return 'Unknown call type';
    }

    if(!groupedCalls) return null;
    const sortedGroups = Object.keys(groupedCalls).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

    return (
        <div className={`border w-full  ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
            <p className={`text-lg font-semibold px-4 py-5 border-b ${theme === 'dark' ? ' border-white' : ' border-black'}`} >
                <FormattedMessage
                    id="calls.recent"
                    defaultMessage="Recent Calls"
                    description="Recent calls header"
                />
            </p>
            {sortedGroups.map(date => {
                if (!groupedCalls[date].length) return null;
                return (
                    <div key={date}>
                        <h6 className="pl-4 py-5 font-semibold ">{date}</h6>
                        {groupedCalls[date].map((call: Call) => (
                            <div className={`cursor-pointer hover:bg-grey-50 ${selectedCallId == call.callId && 'bg-grey-50'}`} key={call.callId} onClick={() => handleCallClick(call.callId)}>

                                <div className={`flex justify-between items-center border-b ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                                    <div className="flex  pl-4 py-4">

                                        <button onClick={() => handleCallClick(call.callId)}>
                                            <Icon iconName={selectedCallId == call.callId ? 'remove' : 'add'} />
                                        </button>

                                    </div>
                                    {getCallDescription(call)}
                                   {getCallType(call.type, call.bridgetAt, call.forwardedTo)}
                                    <div className={'flex items-center'}>
                                        <Icon className="pr-4" iconName="schedule" />
                                        <p className="pr-9">{getTimeAgo(new Date(call.startedAt))}</p>
                                    </div>
                                    {Boolean(call.exposedMsisdn) &&
                                    <div className='pr-8 w-[200px] items-center flex'>
                                        <div className="">{hexToEmoji(call.exposedMsisdn.icon)} </div>
                                        <div>{call?.exposedMsisdn.label ? call?.exposedMsisdn.label : <ClickToCall noPrefix={true} msisdn={call.exposedMsisdn.msisdn}  />}</div>
                                    </div>
                        }
                                </div>
                                {selectedCallId == call.callId && getCallDetails(call)}
                            </div>
                        ))}
                    </div>
                );
            })}
        </div>
    );
};

const getCallDetails = (selectedCall: Call) => {

    const { theme } = useTheme();

        return (
            <div className={`flex flex-col p-4 border-b  border-solid ${theme === 'dark' ? ' border-white' : ' border-black  bg-gray-50'}`} >
                {selectedCall.bridgetAt && <div
                    className="flex gap-5 justify-between py-3.5 text-sm font-semibold leading-5 border-b border-black border-dashed max-md:flex-wrap max-md:max-w-full">
                    <div className="text-zinc-600">Call length</div>
                    <div
                        className="text-right ">     {duration(selectedCall.bridgetAt, selectedCall.finishedAt)}</div>
                </div>}
                { selectedCall.user && <div
                    className={`flex gap-5 justify-between py-3.5 text-sm font-semibold leading-5 border-b  border-dashed max-md:flex-wrap max-md:max-w-full ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                    <div className="text-zinc-600">Member of team</div>
                    <div
                        className="text-right ">{selectedCall.user?.firstName + ' ' + selectedCall.user?.lastName}</div>
                </div>}
                <div className={`flex gap-5 justify-between py-3.5 w-full font-semibold border-b  border-dashed max-md:flex-wrap max-md:max-w-full ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                    <div className="my-auto text-sm leading-5 text-zinc-600">
                        Phone number
                    </div>

                    <div className=" text-[16px] items-center flex">{Boolean(selectedCall.clientMsisdn) && <> <FlagImage className='w-4 h-4 mr-3'
                                                                                                                     iso2={getFlag(selectedCall.clientMsisdn) ?? 'us'}/> <ClickToCall noPrefix={true} msisdn={selectedCall.clientMsisdn}/></>}</div>


                </div>
                {/*<div className="mt-3.5 text-sm font-semibold leading-5 text-zinc-600 max-md:max-w-full">*/}
                {/*    Recordings*/}
                {/*</div>*/}
            </div>

        );
}


export default RecentCallsDetails;
