import {AsYouType, parsePhoneNumber} from "libphonenumber-js/max";
import { ChDropdown, ChInput } from "ch3-ui-lib";
import {useEffect, useState} from "react";
import { useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import {selectSelectedPrefix, setPhoneNumber, setSelectedPrefix} from "../../../store/features/calls/dialerSlice";
import { getCallingCountries } from "../services/countryDataService";
import { FlagImage } from "react-international-phone";
import {CountryCode} from "libphonenumber-js/max";

interface Country {
    name: string | undefined;
    alpha2: string;
    alpha3: string;
    countryCallingCodes: readonly string[];
    currencies: readonly string[];
    emoji: string;
    ioc: string;
    languages: readonly string[];
    status: string;
}

export interface DropdownItem {
    label: string | undefined;
    value: any;
    key: string;
    prefixElement: JSX.Element;
}

interface CallInputProps {
    phoneNumber: string;
    error: string | undefined;
}

export const CallInput = ({ phoneNumber, error }: CallInputProps) => {
    const dispatch = useAppDispatch();

    const selectedPrefix = useSelector(selectSelectedPrefix);
    const [typedNumber, setTypedNumber] = useState(phoneNumber);
    const [phrase, setPhrase] = useState('');
    const [items, setItems] = useState<DropdownItem[]>([]);

    useEffect(() => {
        setTypedNumber(
            new AsYouType(selectedPrefix.isoCountry as CountryCode).input(phoneNumber),
        );
    }, [selectedPrefix, phoneNumber]);
    useEffect(() => {
        if (phoneNumber.startsWith('+')) {
            try {
                const phoneNumberParsed = parsePhoneNumber(phoneNumber);

                if (phoneNumberParsed) {
                    const {country, countryCallingCode} = phoneNumberParsed;

                    if (countryCallingCode) {
                        dispatch(setSelectedPrefix({
                            isoCountry: country ?? 'US',
                            prefix: `+${countryCallingCode}`,
                        }));
                    }
                }
            } catch (e) {
                // ignore as it is not yet a proper number with country code
            }
        }
    }, [phoneNumber]);

    useEffect(() => {
        const filteredCountries = getCallingCountries()
            .filter(
                (callingCountry) =>
                    callingCountry.name &&
                    (callingCountry.name.toLowerCase().includes(phrase.toLowerCase()) ||
                        callingCountry.countryCallingCodes[0].includes(phrase))
            )
            .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0));

        const items: DropdownItem[] = filteredCountries.map((country) => {
            return {
                label: country.name,
                value: country,
                key: country.alpha2,
                prefixElement: <FlagImage size={24} iso2={country.alpha2.toLowerCase()} className={'ml-3'} />,
            };
        });
        setItems(items);
    }, [phrase]);

    const onPressCountry = (prefix: Country) => {

        dispatch(
            setSelectedPrefix({
                isoCountry: prefix.alpha2,
                prefix: prefix.countryCallingCodes[0],
            })
        );
    };

    const flagTrigger = (
        <div className='flex items-center '>
            <FlagImage size={24} iso2={selectedPrefix.isoCountry.toLowerCase()} className={'mr-3'} />
        </div>
    );

    const dropdownGroup = [
        {
            items: items,
            callback: onPressCountry,
        }
    ];




    return (
        <>
            <div className='flex items-end p-2 mb-2 '>
                <ChDropdown onPhraseChange={setPhrase} type={'search'} width={255} height={400} trigger={flagTrigger} dropdownGroup={dropdownGroup} />

            </div>
            <ChInput name={'dialer'} type={'tel'} error={error} value={typedNumber} callback={(val:string) => {
                try {
                  const number =  parsePhoneNumber(val);
                    if (number) {
                        dispatch(setPhoneNumber(number.number));
                    }
                }
                catch (e) {
                 return;
                }
            }} />
        </>
    );
}
