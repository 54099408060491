import * as countryData from 'country-data';
import countries from 'i18n-iso-countries';
const notServedCountries = ['XK', 'AC', 'PN', 'AQ', 'UM', 'TA', 'FX', 'EU'];

import frLocale from 'i18n-iso-countries/langs/fr.json';
import enLocale from 'i18n-iso-countries/langs/en.json';

countries.registerLocale(frLocale);
countries.registerLocale(enLocale);

interface CountryCallingData {
    countryCallingCodes: string[];
    alpha2: string;
    // Add other relevant fields if necessary
}

interface CallingCountries {
    all: CountryCallingData[];
    [key: string]: CountryCallingData | CountryCallingData[];
}

const callingCountries: CallingCountries = countryData.callingCountries as unknown as CallingCountries;

export const isoCountryToPrefix = (isoCountry: string) => {
    const iso = isoCountry ? isoCountry.toUpperCase() : '';
    return callingCountries[iso] && (callingCountries[iso] as CountryCallingData).countryCallingCodes
        ? (callingCountries[iso] as CountryCallingData).countryCallingCodes[0]
        : null;
};

export const getCallingCountries = () =>
    callingCountries.all
        .filter(
            callingCountry =>
                !notServedCountries.some(
                    notServedCountry => notServedCountry === callingCountry.alpha2,
                ),
        )
        .map(country => ({
            ...country,
            name: countries.getName(country.alpha2, 'en'),
        }));
