import React, { useEffect, useState } from "react";
import axios from "axios";
import { Env } from "../vite-env";
import {apiFetchComplete} from "../config.ts";

export const EnvironmentProvider = ({
  children,
}: {
  children: (env: Env) => React.ReactNode;
}) => {
  const [isEnvironmentLoaded, setIsEnvironmentLoaded] = useState(false);
  const [envData, setEnvData] = useState<Env | null>(null);

  useEffect(() => {
    const fetchEnvData = async () => {
      const response = await axios.get("/endpoints.json");
      window.__ENV__ = response.data;
      setEnvData(window.__ENV__);
      console.log('apiFetchComplete set');
      apiFetchComplete(window.__ENV__.URL_WEBAPP_API);
    };

    try {
      fetchEnvData();
    } catch (e) {
    } finally {
      setIsEnvironmentLoaded(true);
    }
  }, []);

  if (!isEnvironmentLoaded) {
    return null;
  }

  return envData && children(envData);
};
