import Header from "../../components/header/Header.tsx";
import {Link} from "react-router-dom";
import {useState, useEffect} from "react";
import {user as initialUser} from "../../utilis/menuItems.tsx";
import {Avatar, Button, ChInput, Icon, Switch} from "ch3-ui-lib";
import {mobileWidth, tabletWidth} from "../../utilis/screenWidthUtils.ts";
import {ClosedDialerMargin, OpenDialerMargin} from "../../utilis/dialerWidthUtils.ts";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store.ts";
import { useTheme } from "next-themes";
import {
    fetchUserProfile,
    fetchUserProfileNotifications, updateMissedCallEmailNotificationEnabled
} from "../../store/features/ProfileSettings/profileSettingsSlice.tsx";
// import {useParams} from "react-router-dom";

const profileMenu = [
    /*{
        icon: 'article',
        label: 'General information',
        subLabel: 'Full name, email, and role',
        value: 'GENERAL_INFORMATION'
    },
    {
        icon: 'call',
        label: 'Phone Numbers',
        subLabel: 'Your assigned numbers',
        value: 'PHONE_NUMBERS'
    },
    {
        icon: 'groups',
        label: 'Groups',
        subLabel: 'Your assigned groups',
        value: 'GROUPS'
    },
    */
    {
        icon: 'notifications',
        label: 'Notification',
        subLabel: 'Alerts about calls',
        value: 'NOTIFICATION'
    },
    /*
    {
        icon: 'settings',
        label: 'Personalization',
        subLabel: 'Customize your account settings',
        value: 'PERSONALIZATION'
    }
    */
];

const phoneNumbers = ['+48791222333', '+48791222334', '+48791222335'];
const groups = ['Group1', 'Group2', 'Group3'];




const ProfileSettings = () => {
    // const {id} = useParams<{ id: string }>();

    const dispatch = useAppDispatch();

    // const [state, setState] = useState('GENERAL_INFORMATION');
    const [state, setState] = useState('NOTIFICATION');
    const [user] = useState(initialUser);
    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);
    const profileSettings = useSelector((state: RootState) => state.profileSettings);

    console.log({
        profileSettings
    })

    useEffect(() => {
        const fetchData = async () => {
            // TODO: get proper userId instead
            const userId = localStorage.getItem('userId')
           await dispatch(fetchUserProfile(Number(userId)));
           await dispatch(fetchUserProfileNotifications(Number(userId)));
        }
        fetchData();
    }, [dispatch]);

    console.log(user);
    const initialFormData = {
        firstName: '',
        lastName: '',
        email: '',
        role: ['owner', 'agent'],
    }

    const [formData, setFormData] = useState(initialFormData);


    const [errors, /*setErrors*/] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumbers: Array(formData?.role?.length).fill(''),
    });

    const handleChange = (value:any, name:string, /*index?:number*/) => {
        const data = {
            ...formData,
            [name]: value,
        };
        setFormData(data);
    };

    const { theme } = useTheme();

    const handleSwitchChange = async () => {
        const userId = localStorage.getItem('userId')
        await dispatch(updateMissedCallEmailNotificationEnabled({
            userId: userId ?? '',
            missedCallEmailNotificationEnabled: !profileSettings.missedCallEmailNotificationEnabled
        }))
    }

    return (
        <div className='w-full'>
            <Header header='You' icon={'1F46A;'}/>

            <div
                className={`flex ${mobileWidth || tabletWidth ? ' px-4' : isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>

                <div
                    className=" w-[300px] min-w-[300px] py-8 flex-col justify-start items-center gap-6 inline-flex mx-8">

                    <div className="flex-col justify-start items-center gap-3 flex">
                        <Avatar size={"xxl"} type='icon' icon={'home'} color={'orange'}/>

                        <div className="flex-col justify-start items-center gap-2 flex">

                            <div
                                className={` text-center text-black text-xl font-semibold  leading-7 ${theme === 'dark' ? 'text-white' : ' '}`}>
                                {profileSettings.firstName} {profileSettings.lastName}
                            </div>
                        </div>
                    </div>


                    <div className="  self-stretch py-4 text-black">
                        {profileMenu.map((item) => (
                            <div key={item.value} onClick={() => setState(item.value)}
                                 className={`cursor-pointer flex gap-6 px-8 py-4 ${state === item.value && theme === 'light'  ? 'bg-information-50' : ' '} ${state === item.value && theme === 'dark'  ? 'bg-[#393C41]' : ' '}`}>
                                <Icon className={`${theme === 'dark' ? ' text-white' : ' '}`} iconName={item.icon}/>
                                <div className="flex flex-col ">
                                    <div className={`text-sm font-semibold leading-5 ${theme === 'dark' ? ' text-white' : ' '}`}>{item.label}</div>
                                    <div className={`mt-1 text-xs leading-5 ${theme === 'dark' ? ' text-white' : ' '}`}>{item.subLabel}</div>
                                </div>

                            </div>
                        ))}
                    </div>

                </div>

                <div
                    className=" flex-col justify-start items-center gap-6 inline-flex px-8 w-full h-[calc(100vh-124px)] overflow-y-scroll overflow-x-hidden">
                    {
                        Boolean(state === 'GENERAL_INFORMATION') &&
                        <div className="flex-col justify-start items-center gap-6 inline-flex mx-8 w-full">
                            <div className='w-full'>
                                <div className={`flex flex-col justify-between border-x border-t ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                                    <div className='font-semibold px-6 pt-6 text-lg'>
                                        General information
                                    </div>
                                    <div className='text-grey-500 px-6 pb-6 text-sm'>
                                        Update name and general information
                                    </div>
                                </div>
                                <div className={`w-full border border-black px-8 gap-6 grid py-8 ${theme === 'dark' ? ' border-white' : ' border-black'}`}>

                                    <div>
                                        <ChInput value={formData.firstName}
                                                 callback={(val: string) => handleChange(val, 'firstName')}
                                                 error={errors.firstName}
                                                 className='max-w-full' label='First name'/>
                                    </div>
                                    <div>
                                        <ChInput value={formData.lastName}
                                                 callback={(val: string) => handleChange(val, 'lastName')}
                                                 error={errors.lastName} className='max-w-full' label='Last name'/>
                                    </div>
                                    <div>
                                        <ChInput value={formData.email}
                                                 callback={(val: string) => handleChange(val, 'email')}
                                                 error={errors.email} className='max-w-full' label='Email adress'/>
                                    </div>
                                    <div>
                                        <ChInput value={formData.email}
                                                 callback={(val: string) => handleChange(val, 'role')}
                                                 className='max-w-full mb-6' label='Role'/>
                                    </div>

                                </div>
                            </div>
                        </div>
                    }
                    {
                        Boolean(state === 'GENERAL_INFORMATION' || state === 'PHONE_NUMBERS') &&
                        <div className={`  w-full border-x border-t  ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                            <div className=''>
                                <div className={`font-semibold px-6 p-6 text-lg border-b ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                                    Assigned phone numbers
                                </div>
                                <div className={`font-semibold px-6 p-6 text-xs border-b ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                                    Phone numbers
                                </div>
                                <div className='w-full  '>
                                    <div className=' w-full '>
                                        {phoneNumbers.map((phoneNumber) => (
                                            <div className={`border-b p-6 ${theme === 'dark' ? ' border-white' : ' border-black'}`}> {phoneNumber} </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>
                    }
                    {
                        Boolean(state === 'GENERAL_INFORMATION' || state === 'GROUPS') &&
                        <div className=  {`w-full border-x border-t ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                            <div className=''>
                                <div className={`font-semibold px-6 p-6 text-lg border-b  ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                                    Assigned groups
                                </div>
                                <div className={`font-semibold px-6 p-6 text-xs border-b  ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                                    Groups
                                </div>
                                <div className='w-full  '>
                                    <div className=' w-full '>
                                        {groups.map((group) => (
                                            <div className={`border-b p-6 ${theme === 'dark' ? ' border-white' : ' border-black'}`}> {group} </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>
                    }
                    {
                        Boolean(state === 'GENERAL_INFORMATION' || state === 'NOTIFICATION') &&
                        <div className='w-full '>
                            <div className={`flex flex-col justify-between border-x border-t  ${theme === 'dark' ? ' border-white' : ' border-black'}`} >
                                <div className={`font-semibold px-6 p-6 text-lg border-b ${theme === 'dark' ? ' border-white' : ' border-black'}`} >
                                    Notification
                                </div>

                                <div className={`w-full border-b  p-6 gap-6 grid items-center ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                                    <div className='my-2'>
                                        <Switch size='lg' checked={profileSettings.missedCallEmailNotificationEnabled}
                                                callback={handleSwitchChange}
                                                label={'Email me about missed incoming calls'}
                                                alignLeft={false}></Switch>

                                    </div>
                                </div>

                            </div>

                        </div>
                    }
                    {/* {
                        Boolean(state === 'GENERAL_INFORMATION' || state === 'PERSONALIZATION') &&
                        <div className='w-full '>
                            <div className={`flex flex-col justify-between border-x border-t ${theme === 'dark' ? ' border-white' : ' border-black'}`}  >
                                <div className={`font-semibold px-6 p-6 text-lg border-b ${theme === 'dark' ? ' border-white' : ' border-black'}`} >
                                Personalization
                                </div>

                                <div className={`w-full border-b  p-6 gap-6 grid items-center ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                                    <div className='my-2'>
                                        <div>
                                            <div>
                                                Theme
                                            </div>
                                            <div>
                                                Choose your application's color scheme.
                                             </div>
                                        </div>
                                        <ThemeSwitcher></ThemeSwitcher>


                                    </div>
                                </div>

                            </div>

                        </div>
                    } */}
                </div>


            </div>
            <div className={` flex justify-end ${isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>
                <div className={`mx-8 mt-8 flex  justify-between`}>

                    <div className='flex gap-4'>
                        <Link to={'/groups'}>
                            <Button size={'large'} label='Cancel' buttonType='secondary'/>
                        </Link>
                        <Button size={'large'} label='Update settings' buttonType='primary'/>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileSettings;
