import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Icon, Switch} from "ch3-ui-lib";
import {useAppDispatch} from "../../../store/store";
import {
    getAutoGainControl,
    getSelectedMicrophone, getSelectedSpeaker, setAutoGainControl,
    setSelectedMicrophoneDevice,
    setSelectedSpeakerDevice
} from "../../../store/features/calls/dialerSlice";
import {useSelector} from "react-redux";


const AudioSettings = () => {

    const dispatch = useAppDispatch();
    const [audioOutputs, setAudioOutputs] = useState([] as MediaDeviceInfo[]);
    const [audioInputs, setAudioInputs] = useState([] as MediaDeviceInfo[]);
    const [inputsOpen, setInputsOpen] = useState(false);
    const [outputsOpen, setOutputsOpen] = useState(false);

    const selectedMicrophone = useSelector(getSelectedMicrophone);
    const selectedSpeaker = useSelector(getSelectedSpeaker);
    const autoGainControl = useSelector(getAutoGainControl);

    useEffect(() => {
        console.log('useEffect');
            navigator.mediaDevices.enumerateDevices()
                .then(devices => {
                    setAudioOutputs(devices.filter(device => device.kind === 'audiooutput'));
                    if(!selectedSpeaker) {
                        dispatch(setSelectedSpeakerDevice(devices.find(device => device.deviceId === 'default')?.deviceId))
                    }
                });
            navigator.mediaDevices.enumerateDevices().then(devices => {
                setAudioInputs(devices.filter(device => device.kind === 'audioinput'));
                if(!selectedMicrophone) {
                    dispatch(setSelectedMicrophoneDevice(devices.find(device => device.deviceId === 'default')?.deviceId))
                }
            });

    }, [dispatch]);
    const toggleInputs = () => {
        setInputsOpen(!inputsOpen);
    }

    const toggleOutputs = () => {
        setOutputsOpen(!outputsOpen);
    }
    const setMicrophone = (deviceId: string) => {
        dispatch(setSelectedMicrophoneDevice(deviceId));
    }
    const setSpeaker = (deviceId: string) => {
        dispatch(setSelectedSpeakerDevice(deviceId));
    }
    const handleChangeAutoGainControl = () => {
        console.log('handleChangeAutoGainControl')
        dispatch(setAutoGainControl(!autoGainControl));
    }


    return (
        <div className="relative h-full z-20 flex flex-col pb-20 max-w-xs bg-white">
            <header className="flex  p-4 text-2xl font-semibold leading-8 text-black whitespace-nowrap">
                <Link to={'/settings'}> <Icon iconName={'arrow_back'} className='cursor-pointer' /></Link>
                <h5>Audio Settings</h5>
            </header>
            <div className="gap-3 self-center px-5 mt-2 w-full  leading-6 text-black max-w-[328px] ">
                <div className='border-b border-black  my-2 pb-4'>
                    <div className='text-base font-semibold flex items-center justify-between ' onClick={toggleOutputs}>
                        <span>Speaker</span>
                        <Icon iconName={outputsOpen ? 'expand_less' : 'expand_more'} />
                    </div>
                    <div className='text-gray-500 text-sm'>{audioOutputs?.find(audioDevice => audioDevice.deviceId == selectedSpeaker)?.label}</div>

                    {outputsOpen && (
                        <div className='grid gap-4 px-5 text-sm'>
                            <div className='mt-3 text-sm font-semibold'>SELECT YOUR OUTPUT DEVICE </div>
                            {audioOutputs.map((speaker) => (
                                <div className='cursor-pointer flex justify-between'>
                                    <div onClick={()=> setSpeaker(speaker.deviceId)}>{speaker.label}</div>
                                    {speaker.deviceId == selectedSpeaker && <Icon className={'p-0 w-4 h-4 cursor-pointer text-primary-400 hover:text-primary-500'} iconName={'check_circle'} /> }
                                </div>

                            ))}
                        </div>
                    )}
                </div>
                <div className='border-b border-black my-2 pb-4'>
                <div className='text-base font-semibold flex items-center justify-between ' onClick={toggleInputs}>

                    <span>Microphones</span>
                    <Icon iconName={inputsOpen ? 'expand_less' : 'expand_more'} />
                </div>
                    <div className='text-gray-500 text-sm'>{audioInputs?.find(audioDevice => audioDevice.deviceId == selectedMicrophone)?.label}</div>
                {inputsOpen && (
                    <div className='grid gap-4 px-5 text-sm'>
                        <div className='mt-3 text-sm font-semibold'>SELECT YOUR INPUT DEVICE</div>
                        {audioInputs.map((microphone) => (
                            <div className='cursor-pointer  flex justify-between'>
                                <div onClick={() =>setMicrophone(microphone.deviceId)}>{microphone.label}</div>
                                {microphone.deviceId == selectedMicrophone && <Icon className={'p-0 w-4 h-4 cursor-pointer text-primary-400 hover:text-primary-500'} iconName={'check_circle'} /> }
                            </div>
                        ))}
                    </div>
                )}
                </div>
            </div>
            <div className='my-2'>
                <Switch size='lg' checked={autoGainControl} callback={() => handleChangeAutoGainControl()} label={'Automatically adjust your microphone settings'} alignLeft={false}></Switch>

            </div>
        </div>
    );
};
export default AudioSettings;
