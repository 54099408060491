import Header from "../../components/header/Header";
import {Avatar, Button, ChInput, ChTooltip} from "ch3-ui-lib";
import {Link} from "react-router-dom";
import { formatDateDayMonth, groupCallsByDate} from "../../utilis/timeUtilis";
import {Call, fetchContactHistory} from "../../store/features/calls/callsSlice";
import {useEffect, useState} from "react";
import RecentCallsDetails from "./RecentCallDetails";
import {FlagImage} from "react-international-phone";
import {useAppDispatch} from "../../store/store";
import {useSelector} from "react-redux";
import { fetchContactById} from "../../store/features/Contacts/contactsSlice";
import {useParams} from "react-router-dom";
import {Colors} from "../../utilis/types/Types";
import { parsePhoneNumber, PhoneNumber} from 'libphonenumber-js';
import {User} from "../../store/features/Users/usersSlice";
import { RootState } from "../../store/store";
import { OpenDialerMargin, ClosedDialerMargin } from "../../utilis/dialerWidthUtils.ts";
import {ClickToCall} from "../../components/ClickToCall/ClickToCall";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";


const ContactDetails = () => {
    const [loading, setLoading] = useState(true);
    const {id} = useParams<{ id: string }>();
    const isMobile = useMediaQuery('(max-width: 960px)');


    const dispatch = useAppDispatch();
    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchContactById(Number(id)));
            await dispatch(fetchContactHistory(Number(id)));

            setLoading(false);
        };
        fetchData();
    }, [ dispatch]);

    const contactData = useSelector((state: any) => state.contacts.contactById);
    const callsData = useSelector((state: any) => state.calls.contactHistory);
    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);

    const phoneNumbers = contactData?.phoneNumbers?.map((phoneNumber: {msisdn: number, main:boolean}) => parsePhoneNumber(`+${phoneNumber.msisdn.toString()}`));

    type ContactTypeKey = keyof typeof ContactType;
    enum ContactType {
        PRIVATE = 'Private',
        PUBLIC = 'Public',
        SHARED = 'Shared'
    }



    const [filter, setFilter] = useState('');
    const calls = callsData.filter((call: Call) =>( call.primaryContact?.lastName.toLowerCase().includes(filter.toLowerCase()) || call.clientMsisdn.toString().includes(filter)) )

    const groupedCalls = groupCallsByDate(calls);

    return (
         !loading &&
        <>
        <div className='w-full'>
            <Header header='Contact details' icon={'1F4D2;'}/>
            <div>
            <div className='ml-8'>
                <Link className='flex gap-4 items-center' to={'/contacts'}>
                    <Button size='small' className='pl-1' buttonType='secondary' rightIcon='arrow_back_ios'/>
                    <div>
                        Back to the contact list
                    </div>
                </Link>
            </div>
            <div className={`flex ` + `${isMobile ? ' px-4 flex-col' : isDialerHidden ? ClosedDialerMargin + ' flex-row' : OpenDialerMargin + ' flex-row'}`}>
                <div className=" w-[300px] min-w-[300px] py-8 flex-col justify-start items-center gap-6 inline-flex m-8">
                    <div className="flex-col justify-start items-center gap-3 flex">
                        <Avatar size={"xxl"} type='initials' color={contactData?.iconColor ?? Colors.auto} name={`${contactData?.firstName + " " + contactData?.lastName}`}/>
                        <div className="flex-col justify-start items-center gap-2 flex">
                            <div
                                className=" text-center  text-xl font-semibold  leading-7">{`${contactData?.firstName + " " + contactData?.lastName}`}</div>
                        </div>
                    </div>
                    <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                        <div className=" text-base font-semibold  leading-normal">Shared for</div>
                        <div className=" justify-start items-center flex">
                            {
                                contactData?.sharedForUsers?.map((user:User, index:number) => (

                                    <ChTooltip trigger={<div style={{right: `${index * 20}px`}}
                                         className={`p-[2px] bg-white relative right-${index}`}>
                                        <Avatar key={index} size="md" type='initials'
                                                color={user.iconColor ?? Colors.auto} name={user.firstName + ' ' + user.lastName}/>

                                    </div>}    content={user.firstName + ' ' + user.lastName} theme={'dark'} />
                                
                                ))}
                        </div>
                        <div
                            className={`border-t  pt-4 self-stretch flex-col justify-start items-start gap-0.5 flex`}>
                            <div className=" text-base font-semibold  leading-normal">Phone numbers</div>
                            {phoneNumbers?.map((phoneNumber: PhoneNumber, index: number) => (
                                <div key={index} className="px-3 py-1.5 justify-start items-center gap-2 inline-flex">
                                    <FlagImage iso2={phoneNumber.country?.toLowerCase() ?? 'us'} className='w-4 h-3'/>
                                    <ClickToCall  noPrefix={true} msisdn={Number(phoneNumber.number)} icon={true} />

                                </div>))
                            }
                        </div>

                        <div
                            className={`border-t  pt-4 self-stretch flex-col justify-start items-start gap-2 flex`}>
                            <div className=" text-base font-semibold  leading-normal">Email</div>
                            <div className="text-smormal  leading-tight">{contactData?.email ?? '---'}</div>
                        </div>

                        <div
                            className={`border-t  pt-4 self-stretch flex-col justify-start items-start gap-2 flex`}>
                            <div className=" text-base font-semibold  leading-normal">Company</div>
                            <div className=" text-sm  leading-tight">{contactData?.company ?? '---'}</div>
                        </div>

                        <div
                            className={`border-t  pt-4 self-stretch flex-col justify-start items-start gap-2 flex`}>
                            <div className=" text-base font-semibold leading-normal">Contact availability
                            </div>
                            <div className=" text-sm  leading-tight">{ContactType[contactData?.availability as ContactTypeKey]}</div>
                        </div>

                        <div

                            className=" border-t border-black self-stretch flex-col justify-start items-start gap-2 flex pt-4">
                            <div className="text-black text-sm  leading-tight">Created by: {' ' + contactData?.owner.firstName + ' ' + contactData?.owner.lastName }, {' ' +formatDateDayMonth(new Date(contactData?.createdAt))}

                            </div>
                        </div>
                    </div>

                    <Link to={`../contacts/${id}`}> <Button onClick={() => console.log('test')} size={'small'} leftIcon={'edit'}
                                                        buttonType='primary' label={'Edit Contact'}/></Link>

                </div>
                <div className=" h-[calc(100vh-300px)] overflow-y-auto py-8 flex-col justify-start items-center gap-6 inline-flex p-4 m-4 w-full ">
                    {/*<div className='w-full flex justify-start gap-2'>*/}
                    {/*    <Button size={'small'} leftIcon='filter_list' buttonType={'secondary'} label={'Show all'}/>*/}
                    {/*    <Button size={'small'} leftIcon='filter_list' buttonType={'secondary'} label={'Calls'}/>*/}
                    {/*    /!*<Button leftIcon='filter_list' buttonType={'secondary'} label={'Messages'}/>*!/*/}
                    {/*</div>*/}
                    <div className='w-full flex justify-start'>
                        <ChInput className='min-w-[375px]'  prefixIcon={'search'}
                                 callback={(value: string) => setFilter(value)} placeholder='Search'/>

                    </div>
                    {groupedCalls ? <RecentCallsDetails groupedCalls={groupedCalls}/> : <div>loading...</div>}
                </div>
            </div>
            </div>

        </div>
        </>
);
}
export default ContactDetails;
