import {isPossiblePhoneNumber, parsePhoneNumber} from 'libphonenumber-js'


export const getFormatedPhoneNumber = (phoneNumber: number | string, noPrefix?:boolean ) => {
    if (!phoneNumber) return '';
    if(isPossiblePhoneNumber(`+${phoneNumber.toString()}`)) {
        if(noPrefix) {
            return parsePhoneNumber(`+${phoneNumber.toString()}`).format('NATIONAL', {v2: true});
        } else {
            return parsePhoneNumber(`+${phoneNumber.toString()}`).format('INTERNATIONAL', {v2: true});
        }
    } else {
        return phoneNumber.toString();
    }

}
export const getFlag = (phoneNumber: number ) => {
    if(isPossiblePhoneNumber(`+${phoneNumber.toString()}`)) {
        return parsePhoneNumber(`+${phoneNumber.toString()}`)?.country?.toLowerCase();
    } else {
        return '';
    }

}
