import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    phoneNumbersFilter: [],
    contactsFilter: [],
    usersAndGroupsFilter: [],
    selectedDates: { from: undefined, to: undefined },
    limit: 10,
};

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setPhoneNumbersFilter: (state, action) => {
            state.phoneNumbersFilter = action.payload;
        },
        setContactsFilter: (state, action) => {
            state.contactsFilter = action.payload;
        },
        setUsersAndGroupsFilter: (state, action) => {
            state.usersAndGroupsFilter = action.payload;
        },
        setSelectedDates: (state, action) => {
            state.selectedDates = action.payload;
        },
        setLimit: (state, action) => {
            state.limit = action.payload;
        },
    },
});

export const {
    setPhoneNumbersFilter,
    setContactsFilter,
    setUsersAndGroupsFilter,
    setSelectedDates,
    setLimit,
} = filtersSlice.actions;

export default filtersSlice.reducer;
