import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {User} from "../Users/usersSlice";
import {Group} from "../groups/groupsSlice";
import {ColorKey} from "../../../utilis/types/Types";
import {filterParams} from "../calls/callsSlice";
import {getApiUrl} from "../../../config";
import {showToast} from "../Notifications/notificationsSlice";

export interface ContactSelectItem {
    contactId: number;
    firstName: string;
    lastName: string;
    phoneNumber: number;
    sharedFor:SharedItem[];
    iconColor?: ColorKey;
}
export interface ContactList {
    contacts: ContactSelectItem[];
    totalCount: number;
}
export type SharedItem = {
    userId?: number;
    groupId?: number;
    iconColor: ColorKey;
    name: string;
    fullName?: string;

}
export interface Contact {
    contactId: number;
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    availability: string;
    createdBy: string;
    phoneNumbers: { msisdn: number; main: boolean }[];
    sharedForUsers: User[];
    sharedForGroups:Group[];
    createdAt: string;
    iconColor?: ColorKey;
    owner: {
        userId: number;
        fullName: string;
        iconColor: ColorKey;
    };
}
export interface ContactDTO {
    contactId?: number;
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    availability: string;
    createdBy: string;
    phoneNumbers: { msisdn: number; main: boolean }[];
    sharedForUsers: User[];
    sharedForGroups:Group[];
    iconColor?: ColorKey;
}
export interface ContactListItem {
    contactId: number;
    firstName: string;
    lastName: string;
    phoneNumber: number;
    sharedForUsers: User[];
    sharedForGroups:Group[];
    availability: string;
}

interface ContactsState {
    contacts: ContactSelectItem[];
    totalCount: number;
    contactById: Contact | null;
    status: string;
    statusContactById: string;
    error: string | undefined;
}
const initialState: ContactsState = {
    contacts: [] as ContactSelectItem[],
    totalCount: 0,
    contactById: null,
    status: 'idle',
    statusContactById: 'idle',
    error: undefined,

}
interface ParamsProps {
    phrase?: string;
    order?: string;
    sortBy?: string;
    sharedForUsers?: number[];
    sharedForGroups?: number[];
    lastContactId?: number;
    lastLastName?: string
    lastFirstName?: string;
    lastMsisdn?: number;
    firstName?: string;
}
const getHeaders = () => ({
    'Content-Type': 'application/json',
    'tenant': localStorage.getItem("tenant"),
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
});

export const addContact = createAsyncThunk<void, ContactDTO, {rejectValue: string}>(
    'contacts/addContact',
    async (contactData, thunkAPI) => {

        const data = {
            ...contactData,
            createdAt: new Date().toISOString(),
            sharedForUsers: contactData.sharedForUsers.map((user) => user.userId),
            sharedForGroups: contactData.sharedForGroups.map((group) => group.id),
        }
        try {
             await axios.post(`${await getApiUrl()}/contact`, data, { headers: getHeaders() });
            thunkAPI.dispatch(showToast({ description: 'Contact added successfully!', type: 'success' }));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                thunkAPI.dispatch(showToast({description: 'Failed to add contact!', type: 'error' }));
                return thunkAPI.rejectWithValue(error.response?.data);
            } else {
                throw error;
            }
        }
    }
);
export const getContacts = createAsyncThunk<ContactList,  ParamsProps , {rejectValue: string}>(
    'contacts/getContacts',
    async ({phrase,lastContactId,sharedForUsers, sharedForGroups, order, sortBy}, thunkAPI) => {
        const userId =Number( localStorage.getItem('userId'));
        try {
            const response = await axios.get(`${await getApiUrl()}/contact/list/${userId}`, {
                headers: getHeaders(),
                params: filterParams({ phrase, lastContactId, sharedForUsers, sharedForGroups, order, sortBy }),
                paramsSerializer: params => {
                    return Object.entries(params).map(([key, value]) => {
                        if (Array.isArray(value)) {
                            if(value.length === 0) return;
                            return `${key}=${ value.join(',')}`;
                        }
                        return `${key}=${value}`;
                    }).join('&');
                }
            });
            return {contacts:response.data, totalCount: response.headers['x-total-count']};
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return thunkAPI.rejectWithValue(error.response?.data);
            } else {
                throw error;
            }
        }
    }
);
export const loadMoreContacts = createAsyncThunk<ContactSelectItem[],  ParamsProps , {rejectValue: string}>(
    'contacts/loadMoreContacts',
    async ({phrase,lastLastName, lastFirstName,lastContactId, lastMsisdn}, thunkAPI) => {
        const userId =Number( localStorage.getItem('userId'));
        try {
            const response = await axios.get(`${await getApiUrl()}/contact/list/${userId}`, {
                headers: getHeaders(),
                params: filterParams({ phrase, lastContactId, lastLastName, lastFirstName, lastMsisdn }),
                paramsSerializer: params => {
                    return Object.entries(params).map(([key, value]) => {
                        if (Array.isArray(value)) {
                            if(value.length === 0) return;
                            return `${key}=${ value.join(',')}`;
                        }
                        return `${key}=${value}`;
                    }).join('&');
                }
            });
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(showToast({description: 'Failed to fetch contacts!', type: 'error' }));
            if (axios.isAxiosError(error)) {
                return thunkAPI.rejectWithValue(error.response?.data);
            } else {
                throw error;
            }
        }
    }
);
export const fetchContactById = createAsyncThunk<Contact, number,  {rejectValue: string}>(
    'contacts/fetchContactById',
    async (id, thunkAPI) => {
        try {
            const response = await axios.get(`${await getApiUrl()}/contact/${id}`, { headers: getHeaders() });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return thunkAPI.rejectWithValue(error.response?.data);
            } else {
                throw error;
            }
        }
    }
);
export const updateContact = createAsyncThunk<void, ContactDTO, {rejectValue: string}>(
    'contacts/updateContact',
    async ( contactData , thunkAPI) => {


       const data = {
            ...contactData,
           sharedForUsers: contactData.sharedForUsers.map((user) => user.userId),
           sharedForGroups: contactData.sharedForGroups.map((group) => group.id),
        }
        try {
           await axios.patch(`${await getApiUrl()}/contact/${contactData.contactId}`, data, { headers: getHeaders() });
            thunkAPI.dispatch(showToast({description: 'Contact updated successfully!', type: 'success' }));
        } catch (error) {
           thunkAPI.dispatch(showToast({description: 'Contact update failed!', type: 'error' }));
            if (axios.isAxiosError(error)) {
                return thunkAPI.rejectWithValue(error.response?.data);
            } else {
                throw error;
            }
        }
    }
);

export const deleteContactById = createAsyncThunk<number, number, {rejectValue: string}>(
    'contacts/deleteContactById',
    async (id, thunkAPI) => {
        try {
            await axios.delete(`${await getApiUrl()}/contact/${id}`, { headers: getHeaders() });
            thunkAPI.dispatch(showToast({description: 'Contact deleted successfully!', type: 'success' }));
            return id;
        } catch (error) {
            thunkAPI.dispatch(showToast({description: 'Contact deletion failed!', type: 'error' }));
            if (axios.isAxiosError(error)) {
                return thunkAPI.rejectWithValue(error.response?.data);
            } else {
                throw error;
            }
        }
    }
);


const contactsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getContacts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getContacts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.contacts = action.payload.contacts;
                state.totalCount = action.payload.totalCount;
            })
            .addCase(getContacts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchContactById.pending, (state) => {
                state.statusContactById = 'loading';
            })
            .addCase(fetchContactById.fulfilled, (state, action) => {
                state.statusContactById = 'succeeded';
                state.contactById = action.payload;

            })
            .addCase(fetchContactById.rejected, (state, action) => {
                state.statusContactById = 'failed';
                state.error = action.error.message;
            })
            .addCase(addContact.fulfilled, (state) => {
                state.status = 'succeeded';
                getContacts({});
            })
            .addCase(updateContact.fulfilled, (state) => {
                state.statusContactById = 'succeeded';
                getContacts({});
            })
            .addCase(deleteContactById.fulfilled, (state, action) => {
                if (typeof action.payload === 'number') {
                    const deletedContactId = action.payload;
                    state.contacts = state.contacts.filter(
                        (contact) => contact.contactId !== deletedContactId
                    );
                } else {
                    console.error('Invalid contactId received:', action.payload);
                }
            })
            .addCase(loadMoreContacts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.contacts = [...state.contacts, ...action.payload];
            });
    },
});


// Export the actions and reducer
export const contactsActions = contactsSlice.actions;
export default contactsSlice.reducer;

