
export const API_URL =
  window.__ENV__?.URL_WEBAPP_API;

export let apiFetchComplete: any;
export let apiFetchFailed: any;

export const apiFetchPromise = new Promise((resolve: any, reject: any) => {
  apiFetchComplete = resolve;
  console.log('apiFetchComplete assigned');
  apiFetchFailed = reject;
});

export const getApiUrl = () => {
  return apiFetchPromise;
};

