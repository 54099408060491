import {FetchGroupsResponse} from "../groups/groupsSlice";
import {ContactSelectItem} from "../Contacts/contactsSlice";
import {Msisdn} from "../Msisdns/msisdnsSlice";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from 'axios';
import {filterParams} from "../calls/callsSlice";
import {getApiUrl} from "../../../config";

interface SearchState {
    contacts: ContactSelectItem[];
    searchGroupUsers: {
        sharedForGroups: any[];
        sharedForUsers: any[];
    }
    msisdns: Msisdn[]
}

const initialState: SearchState = {
    contacts: [] as any[],
    searchGroupUsers: {
        sharedForGroups: [],
        sharedForUsers: [],
    },
    msisdns: [] as any[],
};

interface GroupsParamsProps {
    phrase?: string;
    lastGroupId?: number;
    lastName?: string;
    sortBy?: string;
    msisdn?: number[]
    order?: string;
    filterLimit?: number;
}

export interface UsersParamsProps {
    phrase?: string;
    lastUserId?: number;
    lastLastName?: string;
    lastFirstName?: string;
    limit?: number;
    order?: string;
    sortBy?: string;
}

const getHeaders = () => ({
    'Content-Type': 'application/json',
    'tenant': localStorage.getItem('tenant'),
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
});

export const getContactsByPhrase = createAsyncThunk<ContactSelectItem[], string, { rejectValue: string }>(
    'search/getContactsByPhrase',
    async (phrase, thunkAPI) => {
        const userId = Number(localStorage.getItem('userId'));

        try {
            const response = await axios.get(`${await getApiUrl()}/contact/list/${userId}`, {
                headers: getHeaders(),
                params: filterParams({phrase}),
            });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return thunkAPI.rejectWithValue(error.response?.data);
            } else {
                throw error;
            }
        }
    }
);

export const getMsidnsByPhrase = createAsyncThunk<any, string | undefined>('search/getMsidnsByPhrase', async (phrase?) => {
    const userId = localStorage.getItem('userId')
    const response = await axios.get(`${await getApiUrl()}/msisdn/user/${userId}`,
        {
            headers: getHeaders(),
            params: filterParams({phrase})
        });
    return response.data;
});
export const getGroupsByPhrase = createAsyncThunk<FetchGroupsResponse[], GroupsParamsProps>('search/fetchGroups', async ({
                                                                                                                             phrase,
                                                                                                                             lastGroupId,
                                                                                                                             lastName,
                                                                                                                             sortBy,
                                                                                                                             order,
                                                                                                                             msisdn,
                                                                                                                             filterLimit
                                                                                                                         }) => {
    const userId = Number(localStorage.getItem('userId'));
    try {
        const response = await axios.get(`${await getApiUrl()}/group/list/${userId}`, {
            headers: getHeaders(),
            params: filterParams({phrase, lastGroupId, lastName, sortBy, msisdn, order, filterLimit}),
            paramsSerializer: params => {
                return Object.entries(params).map(([key, value]) => {
                    if (Array.isArray(value)) {
                        if (value.length === 0) return;
                        return `${key}=${value.join(',')}`;
                    }
                    return `${key}=${value}`;
                }).join('&');
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
});

export const fetchUsers = createAsyncThunk<FetchGroupsResponse[], UsersParamsProps>('search/fetchUsers', async ({
                                                                                                                    phrase,
                                                                                                                    lastUserId,
                                                                                                                    lastLastName,
                                                                                                                    lastFirstName,
                                                                                                                    sortBy,
                                                                                                                    order
                                                                                                                }) => {
    try {
        const response = await axios.get(`${await getApiUrl()}/user/filter`, {
            headers: getHeaders(),
            params: filterParams({phrase, lastUserId, lastLastName,lastFirstName, sortBy, order}),
            paramsSerializer: params => {
                return Object.entries(params).map(([key, value]) => {
                    if (Array.isArray(value)) {
                        if (value.length === 0) return;
                        return `${key}=${value.join(',')}`;
                    }
                    return `${key}=${value}`;
                }).join('&');
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
});

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getContactsByPhrase.fulfilled, (state, action: PayloadAction<any>) => {
            state.contacts = action.payload;
        })
        builder.addCase(getMsidnsByPhrase.fulfilled, (state, action: PayloadAction<any>) => {
            state.msisdns = action.payload;
        })
        builder.addCase(getGroupsByPhrase.fulfilled, (state, action: PayloadAction<any>) => {
            state.searchGroupUsers.sharedForGroups = action.payload;
        })
        builder.addCase(fetchUsers.fulfilled, (state, action: PayloadAction<any>) => {
            state.searchGroupUsers.sharedForUsers = action.payload;
        });
    },
});

export default searchSlice.reducer;
