import {Avatar, Icon, ChTooltip, Button} from 'ch3-ui-lib';
import {FormattedMessage} from 'react-intl';
import {Call} from '../../store/features/calls/callsSlice';
import {getFormatedPhoneNumber, getFlag} from "../../utilis/msisdnUtilis";
import {Colors} from "../../utilis/types/Types";
import {Link} from "react-router-dom";
import listEmpty from "../../assets/list-empty.svg";
import {hexToEmoji} from "../../components/Emoji/emoji";
import {getCallType, getCallTypeDescription} from "../../utilis/callsUtilis";
import {useEffect, useRef} from "react";
import {throttle} from "lodash";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import {getTimeAgo} from "../../utilis/timeUtilis";
import {FlagImage} from "react-international-phone";


interface RecentCallsProps {
    groupedCalls: { [key: string]: Call[] };
    reachedEnd: Function;
}

const RecentCalls = ({groupedCalls, reachedEnd}: RecentCallsProps) => {
    const isMobile = useMediaQuery('(max-width: 960px)');

    const listContainerRef = useRef<HTMLDivElement | null>(null);
    if (!groupedCalls) return null;
    const sortedGroups = Object.keys(groupedCalls).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

    const reachedEndThrottled = throttle(() => {
        reachedEnd();
    }, 2000);


    const handleScroll = (e: any) => {
        const bottom = (e.target.scrollHeight - (e.target.scrollTop + 5)) <= e.target.clientHeight;
        if (bottom) {

            reachedEndThrottled();
        }
    };


    useEffect(() => {
        const listContainer = listContainerRef.current;
        if (listContainer) {
            listContainer.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (listContainer) {
                listContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    return (

        <div ref={listContainerRef} className='h-[calc(100vh-300px)] overflow-y-auto pr-4 mr-4'>
            <div className='border w-full border-x-black border-t-black'>

                <p className='text-lg font-semibold px-4 py-5 border-b border-black'>
                    <FormattedMessage id="calls.recent" defaultMessage="Recent Calls"
                                      description="Recent calls header"/>
                </p>
                {sortedGroups.map(date => (
                    <div key={date}>
                        {Boolean(isMobile) && <p className='text-[12px] font-semibold pl-4 py-5 border'>{date}</p>}
                        {Boolean(!isMobile) && <h6 className='pl-4 py-5 font-semibold '>{date}</h6>}
                        {groupedCalls[date].map((call: Call) => (
                            <Link to={`/calls/details/${call.callId}`}>
                                <div key={call.callId}
                                     className={`flex justify-between border-b border-black ` + `${isMobile ? ' flex-col' : ' flex-row'} `}>
                                    <div>
                                    <div className='flex items-center pl-4 py-4 '>
                                        {Boolean(call.primaryContact) &&
                                        <Avatar size="md" type={call.primaryContact ? 'initials' : 'default'}
                                        color={call.primaryContact?.iconColor ?? Colors.auto}
                                        name={call.primaryContact ? (call.primaryContact.firstName + ' ' + call.primaryContact.lastName) : ''}/>
                                        }
                                        {Boolean(call.internalCallee) &&
                                        <Avatar size="md" type={call.internalCallee ? 'initials' : 'default'}
                                        color={call.internalCallee?.iconColor ?? Colors.auto}
                                        name={call.internalCallee ? (call.internalCallee.firstName + ' ' + call.internalCallee.lastName) : ''}/>
}
                                        {Boolean(!call.internalCallee && call.primaryContact) &&
                                            <p className='pl-3'>{ call.primaryContact?.firstName + ' ' + call.primaryContact?.lastName} </p>
                                            }
                                        {Boolean(!call.internalCallee && !call.primaryContact) &&
                                            <>
                                                <Avatar size="sm" type={'default'} color={Colors.auto}/>
                                                <div className='flex'>
                                                    <FlagImage size={16} className='w-3 h-3 ml-3'
                                                               iso2={getFlag(call.clientMsisdn) ?? 'us'}/>
                                                    <div className='ml-3'>{getFormatedPhoneNumber(call?.clientMsisdn, true)}</div>
                                                </div>

         </>

                                            }
                                        <p className='pl-3'>{ call.internalCallee && call.internalCallee.firstName + ' ' + call.internalCallee.lastName}</p>
                                    </div>
                                    {Boolean(!call.internalCallee && !call.primaryContact) &&
                                    <Link to={`../contacts/new?phoneNumber=${call.clientMsisdn}`}>
                                <Button size={'small'} className={'pl-4 pb-4 pt-0'} leftIcon='person_add_alt' buttonType={'textPrimary'}
                                        label={'Add contact'}/>

                            </Link>
}
                                    </div>

                                    <div
                                        className={`flex` + `${isMobile ? ' flex-col items-start px-2' : ' flex-row items-center px-0 w-1/4 min-w-[395px]'}`}>
                                        <div className={'flex items-center w-full'}>
                                            <div className='flex items-center w-1/2'>

                                            <ChTooltip
                                                                        alignArrow='start'
                                                                        trigger={<div>{getCallType(call.type, call.bridgetAt, call.forwardedTo)}</div>}
                                                                        content={getCallTypeDescription(call.type, call.bridgetAt)}
                                                                               >

                                                                    </ChTooltip>

                                                {call.exposedMsisdn && <>
                                                    <div
                                                        className="text-[24px]">{hexToEmoji(call.exposedMsisdn.icon)}</div>
                                                    <p className=' ml-2 w-[200px] truncate'>{call.exposedMsisdn.label ? call.exposedMsisdn.label : getFormatedPhoneNumber(call.exposedMsisdn.msisdn)}</p>
                                                </>}
                                                {
                                                    call.internalCallee && <>

                                                            <p className=' ml-2 w-[200px] truncate'>{call.user.firstName + ' ' + call.user.lastName} </p>
                                                        </>
                                                }


                                            </div>
                                            <div className='flex items-center w-1/2'>
                                                <Icon className='pr-4' iconName='schedule'/>
                                                <p className='pr-9'>{getTimeAgo(new Date(call.startedAt))}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Link>
                        ))}
                    </div>
                ))}

                {sortedGroups.length === 0 &&
                    <div className='flex text-center  justify-center '>
                        <div className='w-[450px]  m-14'>
                            <div className='flex  justify-center w-full'>
                                <img className='m-4' src={listEmpty}/>
                            </div>
                            <h1 className='font-bold'>
                                No calls yet!
                            </h1>
                            <div className='mb-6 mt-2'>
                                Start making calls and they'll show up here.
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div>


    );
};


export default RecentCalls;
