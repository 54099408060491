import { useCallback, useEffect, useState } from 'react';
import {  useSelector } from 'react-redux';
import { Avatar, Button, ChDropdown, ChTabs, Datepicker } from 'ch3-ui-lib';
import { useIntl } from 'react-intl';
import { groupCallsByDate } from '../../utilis/timeUtilis';
import { Call, fetchHistoryList } from '../../store/features/calls/callsSlice';
import { RootState, useAppDispatch } from '../../store/store';
import RecentCalls from './RecentCalls';
import MissedCalls from './MissedCalls';
import Header from '../../components/header/Header';
import { MsisdnSelectItem } from '../../store/features/Msisdns/msisdnsSlice';
import { debounce } from 'lodash';
import {Contact, ContactSelectItem} from '../../store/features/Contacts/contactsSlice';
import {
    fetchUsers,
    getContactsByPhrase, getGroupsByPhrase,
    getMsidnsByPhrase
} from '../../store/features/Searches/searchSlice';
import {
    setContactsFilter, setLimit,
    setPhoneNumbersFilter, setSelectedDates,
    setUsersAndGroupsFilter
} from "../../store/features/Filters/filtersSlice";
import {hexToEmoji} from "../../components/Emoji/emoji";
import {getFormatedPhoneNumber} from "../../utilis/msisdnUtilis";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import {OpenDialerMargin} from "../../utilis/dialerWidthUtils.ts";


export default function Calls() {
    const dispatch = useAppDispatch();
    const intl = useIntl();


    const [loading, setLoading] = useState(true);
    const [phonePhrase, setPhonePhrase] = useState<string>('');
    const [contactPhrase, setContactPhrase] = useState<string>('');
    const [userGroupPhrase, setUserGroupPhrase] = useState<string>('');

    const isMobile = useMediaQuery('(max-width: 960px)');

    const historyList = useSelector((state: RootState) => state.calls.historyList);
    const recentCallsCount = useSelector((state: RootState) => state.calls.totalCount);
    const userMsisdns = useSelector((state: RootState) => state.search.msisdns);
    const userGroupsAndUsers = useSelector((state: RootState) => state.search.searchGroupUsers);
    const userContacts = useSelector((state: RootState) => state.search.contacts);

    const {
        phoneNumbersFilter,
        contactsFilter,
        usersAndGroupsFilter,
        selectedDates,
        limit,
    } = useSelector((state: RootState) => state.filters);

    const groupedCalls = groupCallsByDate(historyList);
    const missedCalls = groupCallsByDate(historyList.filter((call: Call) => call.type === 'INBOUND' && !call.bridgetAt));

    const phoneNumbersFilterButton = <Button size='small' label='Phone numbers' leftIcon='filter_list' buttonType='secondary'/>;
    const contactsFilterButton = <Button size='small' label='Contacts' leftIcon='filter_list' buttonType='secondary'/>;
    const usersAndGroupsFilterButton = <Button size='small' label='Users and groups' leftIcon='filter_list' buttonType='secondary'/>;

    const phoneNumbers = userMsisdns?.map((msisdn: any) => (
        {
            label: <div className='flex'>
                <div className='mr-2'>{hexToEmoji(msisdn.icon)}</div>
                {msisdn.label}</div>,
            value: msisdn,
            key: msisdn.msisdnId
        }));

    const contacts = userContacts?.concat(contactsFilter)
        .reduce<{ map: Map<number, boolean>; result: any }>((acc, contact) => {
            if (!acc.map.has(contact.contactId)) {
                acc.map.set(contact.contactId, true);
                acc.result.push({
                    key: contact.contactId,
                    label: contact.firstName + ' ' + contact.lastName,
                    description: getFormatedPhoneNumber(contact.phoneNumber),
                    hideDescription: true,
                    value: contact,
                    prefixElement: <div className='mx-2'>
                        <Avatar size="xs" type='initials' color={contact.iconColor ?? 'auto'}
                                name={contact.firstName + ' ' + contact.lastName}/>
                    </div>,
                    type: 'checkbox'
                });
            }
            return acc;
        }, {map: new Map(), result: []})
        .result;

    const users = userGroupsAndUsers.sharedForUsers ? userGroupsAndUsers.sharedForUsers?.map((user) => ({
        key: `user${user.userId}`,
        label: user.firstName + ' ' + user.lastName,
        value: user,
        prefixElement: <div className='mx-2'><Avatar size="xs" name={user.firstName + ' ' + user.lastName}
                                                     type='initials' color={user.iconColor ?? 'orange'}/></div>,
    })) : [];

    const groups = userGroupsAndUsers.sharedForGroups ? userGroupsAndUsers.sharedForGroups?.map((group) => ({
        key: `group${group.groupId}`,
        label: group.name,
        value: group,
        prefixElement: <div className='mx-2'><Avatar size="xs" type='icon' color={group.iconColor ?? 'auto'}
                                                     icon={group.icon ?? 'home'}/></div>,
        type: 'checkbox'
    })) : [];

    const mapToGroup = (items: any, callback: Function) => {
        return [{
            items: items,
            callback: callback,
            type: 'checkbox'
        }];
    };

    const handleContactSelect = (value: ContactSelectItem) => {
        const exists = contactsFilter.some((item: Contact) => item?.contactId === value.contactId);
        const newContactsFilter = exists
            ? contactsFilter.filter((item: Contact) => item.contactId !== value.contactId)
            : [...contactsFilter, value];
        dispatch(setContactsFilter(newContactsFilter));
    };

    const handleUserAndGroupSelect = (value: any) => {
        const exists = usersAndGroupsFilter.some((item:any )=> item?.userId === value?.userId || item?.groupId === value?.groupId);
        const newUsersAndGroupsFilter = exists
            ? usersAndGroupsFilter.filter((item: any) => item.userId !== value?.userId && item?.groupId !== value?.groupId)
            : [...usersAndGroupsFilter, value];
        dispatch(setUsersAndGroupsFilter(newUsersAndGroupsFilter));
    }

    const handlePhoneNumberSelect = (value: MsisdnSelectItem) => {
        const exists = phoneNumbersFilter.some((item:any) => item === value);
        const newPhoneNumbersFilter = exists
            ? phoneNumbersFilter.filter(item => item !== value)
            : [...phoneNumbersFilter, value];
        dispatch(setPhoneNumbersFilter(newPhoneNumbersFilter));
    }

    const handleDateSelect = (value: any) => {
        const from = new Date(value[0].valueOf()).toISOString();
        const to = new Date(value[1].valueOf()).toISOString();
        dispatch(setSelectedDates({ from, to }));
    }

    const handleBottomReach = () => {
        if (recentCallsCount > limit) {
            dispatch(setLimit(limit + 50));
        }
    }

    const tabs = [
        {
            id: '1',
            label: intl.formatMessage({id: 'calls.recent'}),
            content: groupedCalls ? <RecentCalls reachedEnd={handleBottomReach} groupedCalls={groupedCalls}/> : <div>loading...</div>
        },
        {
            id: '3',
            label: intl.formatMessage({id: 'calls.missed'}),
            content: <MissedCalls groupedCalls={missedCalls || {}}/>
        }
    ];

    const dropdownGroupPhoneNumbers = mapToGroup(phoneNumbers, handlePhoneNumberSelect);
    const dropdownGroupContacts = mapToGroup(contacts, handleContactSelect);
    const dropdownGroupUsersAndGroups = [
        {
            title: 'Users',
            items: users,
            callback: handleUserAndGroupSelect,
            type: 'checkbox'
        },
        {
            title: 'Groups',
            items: groups,
            callback: handleUserAndGroupSelect,
            type: 'checkbox'
        }
    ];

    useEffect(() => {
        let interval: number;
        const fetchData = async () => {
            if (phoneNumbersFilter.length === 0 && contactsFilter.length === 0 && usersAndGroupsFilter.length === 0 && !selectedDates.from && !selectedDates.to) {
                await dispatch(fetchHistoryList({limit}));
            } else {
                const from = selectedDates?.from;
                const to = selectedDates?.to;
                const contacts = contactsFilter.map((contact: ContactSelectItem) => contact.contactId);
                const phoneNumbers = phoneNumbersFilter.map((phoneNumber: MsisdnSelectItem) => phoneNumber.msisdn);
                const users = usersAndGroupsFilter.filter((item:any) => item.hasOwnProperty('userId')).map((item: any) => item.userId);
                const groups = usersAndGroupsFilter.filter((item:any) => item.hasOwnProperty('groupId')).map((item: any) => item.groupId);

                await dispatch(fetchHistoryList({limit, contacts, phoneNumbers, groups, from, to, users}));
            }
            setLoading(false);
        };

        interval = setInterval(fetchData, 30000);
        fetchData();

        return () => clearInterval(interval);
    }, [phoneNumbersFilter, contactsFilter, usersAndGroupsFilter, selectedDates, limit]);

    const fetchInitialData = async () => {
        await dispatch(fetchUsers({ phrase: '' }));
        await dispatch(getGroupsByPhrase({ phrase: '' }));
        await dispatch(getContactsByPhrase(''));
        await dispatch(getMsidnsByPhrase(''));
    };

    useEffect(() => {
        fetchInitialData();
    }, [dispatch]);

    const fetchContacts = async () => {
        await dispatch(getContactsByPhrase(contactPhrase));
    };

    const fetchMsisdns = async () => {
        await dispatch(getMsidnsByPhrase(phonePhrase));
    };

    const fetchUsersGroups = async () => {
        await dispatch(fetchUsers({ phrase: userGroupPhrase }));
        await dispatch(getGroupsByPhrase({ phrase: userGroupPhrase }));
    };

    const debouncedFetchContacts = useCallback(debounce(fetchContacts, 500), [contactPhrase, dispatch]);
    const debouncedFetchMsisdns = useCallback(debounce(fetchMsisdns, 500), [phonePhrase, dispatch]);
    const debouncedFetchUsersGroups = useCallback(debounce(fetchUsersGroups, 500), [userGroupPhrase, dispatch]);

    useEffect(() => {
        if (contactPhrase.length === 0) {
            debouncedFetchContacts();
        }
        if (contactPhrase?.length < 3) return;
        debouncedFetchContacts();

        return () => {
            debouncedFetchContacts.cancel();
        };
    }, [contactPhrase, debouncedFetchContacts]);

    useEffect(() => {
        if (phonePhrase.length === 0) {
            debouncedFetchMsisdns();
        }
        if (phonePhrase?.length < 3) return;
        debouncedFetchMsisdns();
        return () => {
            debouncedFetchMsisdns.cancel();
        }
    }, [phonePhrase, debouncedFetchMsisdns]);

    useEffect(() => {
        if (userGroupPhrase?.length === 0) {
            debouncedFetchUsersGroups();
        }
        if (userGroupPhrase?.length < 3) return;
        debouncedFetchUsersGroups();
        return () => {
            debouncedFetchUsersGroups.cancel();
        }
    }, [userGroupPhrase, debouncedFetchUsersGroups]);

    return (
        <>
            {!loading && (
                <div className='w-full'>

                    <Header header='Calls' icon={'1F919'}/>
                    <div className={`${isMobile ? ' px-[16px]' : OpenDialerMargin + ' m-8 px-0'}`}>
                        <div className={`mt-4 mb-6 flex items-start ${isMobile ? ' flex-wrap' : ' gap-4 flex-nowrap'}`}>
                            <Datepicker onDateConfirm={handleDateSelect}/>
                            <ChDropdown trigger={phoneNumbersFilterButton}
                                        type='search'
                                        onPhraseChange={(value: string) => setPhonePhrase(value)}
                                        dropdownGroup={dropdownGroupPhoneNumbers ?? []}
                                        selected={phoneNumbersFilter}></ChDropdown>
                            <ChDropdown trigger={contactsFilterButton}
                                        type='search'
                                        onPhraseChange={(value: string) => setContactPhrase(value)}
                                        dropdownGroup={dropdownGroupContacts ?? []}
                                        selected={contactsFilter}></ChDropdown>
                            <ChDropdown trigger={usersAndGroupsFilterButton}
                                        type='search'
                                        onPhraseChange={(value: string) => {
                                            setUserGroupPhrase(value)
                                        }}
                                        dropdownGroup={dropdownGroupUsersAndGroups ?? []}
                                        selected={usersAndGroupsFilter}></ChDropdown>
                        </div>
                        <ChTabs activeTabId={tabs[0].id} tabs={tabs}/>
                    </div>
                </div>
            )}
        </>
    );
}
