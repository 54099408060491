import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {getUserMsisdns, Msisdn} from "../Msisdns/msisdnsSlice";
import {filterParams} from "../calls/callsSlice";
import {getApiUrl} from "../../../config";

// Load the base URL from environment variables
interface MsisdnSettingsInfo {
    msisdn: Msisdn;
    users: any[];
    groups: any[];
    emailsSetting: {
        enabled: boolean;
        email: string | null;
    };
    callRecordingEnabled: boolean;
}
export interface WorkingHoursInterface {
    type: string;
    enabled: boolean;
    timeZone: any;
    workingHours: Array<{
        day: string;
        from: string;
        to: string;
        enabled: boolean;
    }>;
}

interface CallRules {
    msisdnId?: string;
    workingWelcomeMessageUrl?: string;
    workingWelcomeMessageEnabled: boolean;
    workingForwardEnabled?: boolean;
    workingForwardMsisdn?: number | null;
    nonWorkingWelcomeMessageUrl?: string;
    nonWorkingForwardEnabled?: boolean;
    nonWorkingForwardMsisdn?: number | null;
    nonWorkingAfterHoursMessageEnabled: boolean;
    nonWorkingAfterHoursMessageUrl?: string;
    nonWorkingAfterHoursMessageOriginalName: string | null | undefined;
    nonWorkingAfterHoursMessageBucketName: string | null | undefined
    nonWorkingWelcomeMessageBucketName: string | null | undefined,
    workingWelcomeMessageOriginalName: string | null | undefined,
    workingWelcomeMessageBucketName: string | null | undefined,

}

export interface CallRulesDTO {
    msisdnId?: string;
    workingForwardEnabled: boolean;
    workingForwardMsisdn: number | null;
    workingWelcomeMessageEnabled: boolean;
    nonWorkingForwardEnabled: boolean;
    nonWorkingForwardMsisdn: number | null;
    nonWorkingDontAcceptCalls: boolean;
    nonWorkingAfterHoursMessageEnabled: boolean;
    nonWorkingAfterHoursMessageOriginalName: string | null | undefined;
    nonWorkingAfterHoursMessageBucketName: string | null | undefined
    nonWorkingWelcomeMessageBucketName: string | null | undefined,
    workingWelcomeMessageOriginalName: string | null | undefined,
    workingWelcomeMessageBucketName: string | null | undefined,
}
export interface AudioFilesURL {
    workingWelcomeMessageUrl: string | undefined;
    nonWorkingWelcomeMessageUrl: string | undefined;
    nonWorkingAfterHoursMessageUrl: string | undefined;
}

interface MsisdnSettingsState {
    info: MsisdnSettingsInfo | null;
    workingHours: WorkingHoursInterface | null;
    audioFilesURL: AudioFilesURL;
    tempFileData: {
        bucketFileName: string | undefined,
        originalFileName: string | undefined,
        audioURL: string | undefined,
    }
    callRules: CallRules | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}
interface AxiosErrorResponse {
    response?: {
        status: number;
        data: any;
    };
    message: string;
}
const initialState: MsisdnSettingsState = {
    info: null,
    workingHours: null,
    callRules:  {
        msisdnId: '',
        workingWelcomeMessageUrl: '',
        workingForwardEnabled: false,
        workingForwardMsisdn: null,
        workingWelcomeMessageEnabled: false,
        nonWorkingForwardEnabled: false,
        nonWorkingForwardMsisdn: null,
        nonWorkingAfterHoursMessageEnabled: false,
        nonWorkingAfterHoursMessageUrl: '',
        nonWorkingAfterHoursMessageOriginalName: '',
        nonWorkingAfterHoursMessageBucketName: '',
        nonWorkingWelcomeMessageBucketName: '',
        workingWelcomeMessageOriginalName: '',
        workingWelcomeMessageBucketName: '',
    },
    audioFilesURL: {
        workingWelcomeMessageUrl: '',
        nonWorkingWelcomeMessageUrl: '',
        nonWorkingAfterHoursMessageUrl: '',
    },
    tempFileData: {
        bucketFileName: undefined,
        originalFileName: undefined,
        audioURL: undefined,
    },
    status: 'idle',
    error: null,
};



const getHeaders = () => ({
    'Content-Type': 'application/json',
    'tenant': localStorage.getItem("tenant"),
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
});


// Thunks for GET requests
export const getMsisdnSettingsInfo = createAsyncThunk(
    'msisdnSettings/getInfo',
    async (id: any) => {
        const response = await axios.get(`${await getApiUrl()}/msisdn/settings/info/${id}`,{
            headers: getHeaders()
        });
        return response.data;
    }
);

export const getMsisdnSettingsWorkingHours = createAsyncThunk(
    'msisdnSettings/getWorkingHours',
    async (id: any) => {
        const response = await axios.get(`${await getApiUrl()}/msisdn/settings/wh/${id}`, {
            headers: getHeaders()
        });
        return response.data;
    }
);

export const getMsisdnSettingsCallRules = createAsyncThunk(
    'msisdnSettings/getCallRules',
    async (id: any) => {
        const response = await axios.get(`${await getApiUrl()}/msisdn/settings/cr/${id}`, {
            headers: getHeaders()
        });
        return response.data;
    }
);

// Thunks for PUT requests
export const updateMsisdnSettingsWorkingHours = createAsyncThunk(
    'msisdnSettings/updateWorkingHours',
    async ({ id, data }: { id: any; data: WorkingHoursInterface }) => {
        await axios.put(`${await getApiUrl()}/msisdn/settings/wh/${id}`, data, {
            headers: getHeaders()
        });
        return data;
    }
);

export const updateMsisdnSettingsCallRules = createAsyncThunk(
    'msisdnSettings/updateCallRules',
    async ({ id, data }: { id: any; data: CallRules }) => {
        const response = await axios.put(`${await getApiUrl()}/msisdn/settings/cr/${id}`, data, {
            headers: getHeaders()
        });
        return response.data;
    }
);
export const updateMsisdnSettingsInfo = createAsyncThunk(
    'msisdnSettings/updateInfo',
    async ({ id, data }: { id: any; data: MsisdnSettingsInfo }) => {
        const response = await axios.put(`${await getApiUrl()}/msisdn/settings/info/${id}`, data, {
            headers: getHeaders()
        });
        return response.data;
    }
);
export const postRecordingPreview = createAsyncThunk('msisdnSettings/postRecordingPreview', async  ({ id, data, audioURL }: { id: any; data: FormData, audioURL: any }) => {
    const config = {
        headers: {
            'tenant': localStorage.getItem("tenant"),
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
        },
    };
    const response = await axios.put(`${await getApiUrl()}/msisdn/settings/cr/${id}/message`, data, config);

    return {...response.data, audioURL};
});
export const getRecording = createAsyncThunk<
    { data: any, type: string },
    { id: any, type: string },
    { rejectValue: { error: string, status: number | string, details: any, type: string } }
    >(
    'msisdnSettings/getRecording',
    async ({ id, type }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${await getApiUrl()}/msisdn/settings/cr/${id}/message`, {
                responseType: 'arraybuffer',
                headers: getHeaders(),
                params: filterParams({ type })
            });

            const data = {
                data: response.data,
                headers: response.headers
            };

            return { data, type };
        } catch (err) {
            const error = err as AxiosErrorResponse;
            if (error.response && error.response.status === 400) {
                return rejectWithValue({ error: 'Bad Request', status: 400, details: error.response.data, type });
            } else {
                return rejectWithValue({ error: error.message, status: error.response ? error.response.status : 'unknown', details: error.response ? error.response.data : null, type });
            }
        }
    }
);
export const setTempFileData = createAsyncThunk('msisdnSettings/setTempFileData', async  ({bucketFileName, originalFileName, audioURL}: {bucketFileName: string | undefined, originalFileName: string | undefined, audioURL: string | undefined}) => {
    return {bucketFileName, originalFileName, audioURL};
});

const msisdnSettingsSlice = createSlice({
    name: 'msisdnSettings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMsisdnSettingsInfo.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getMsisdnSettingsInfo.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.info = action.payload;
            })
            .addCase(getMsisdnSettingsInfo.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to fetch MSISDN settings info';
            })
            .addCase(getMsisdnSettingsWorkingHours.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getMsisdnSettingsWorkingHours.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.workingHours = action.payload;
            })
            .addCase(getMsisdnSettingsWorkingHours.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to fetch working hours';
            })
            .addCase(getMsisdnSettingsCallRules.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getMsisdnSettingsCallRules.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.callRules = action.payload;
            })
            .addCase(getMsisdnSettingsCallRules.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to fetch call rules';
            })
            .addCase(updateMsisdnSettingsWorkingHours.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateMsisdnSettingsWorkingHours.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.workingHours = action.payload;
                getUserMsisdns();
            })
            .addCase(updateMsisdnSettingsWorkingHours.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to update working hours';
            })
            .addCase(updateMsisdnSettingsCallRules.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateMsisdnSettingsCallRules.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.callRules = action.payload;
                getUserMsisdns();
            })
            .addCase(updateMsisdnSettingsCallRules.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to update call rules';
            })
            .addCase(updateMsisdnSettingsInfo.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateMsisdnSettingsInfo.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.info = action.payload;
                getUserMsisdns();
            })
            .addCase(updateMsisdnSettingsInfo.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to update msisdn settings info';
            })
            .addCase(postRecordingPreview.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(postRecordingPreview.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.tempFileData = action.payload;
            })
            .addCase(postRecordingPreview.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to post recording preview';
            })
            .addCase(getRecording.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getRecording.fulfilled, (state, action) => {
                state.status = 'succeeded';

                const blob = new Blob([action.payload.data.data], { type: action.payload.data.headers.toString() });
                const audioUrl = URL.createObjectURL(blob);
                switch (action.payload.type ) {
                    case 'WORKING_HOURS_WELCOME_MESSAGE':
                        state.audioFilesURL = {
                            ...state.audioFilesURL as AudioFilesURL,
                            workingWelcomeMessageUrl: audioUrl
                        }
                        break;
                    case 'NON_WORKING_HOURS_WELCOME_MESSAGE':
                        state.audioFilesURL = {
                            ...state.audioFilesURL as AudioFilesURL,
                            nonWorkingWelcomeMessageUrl:audioUrl
                        }
                        break;
                        case 'AFTER_HOURS_MESSAGE':
                            console.log('audioUrl', audioUrl)
                        state.audioFilesURL = {
                            ...state.audioFilesURL as AudioFilesURL,
                            nonWorkingAfterHoursMessageUrl: audioUrl
                        }
                }
            })
            .addCase(getRecording.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to get recording';
                if (action.payload) {
                    switch (action.payload.type) {
                        case 'WORKING_HOURS_WELCOME_MESSAGE':
                            state.audioFilesURL.workingWelcomeMessageUrl = '';
                            break;
                        case 'AFTER_HOURS_MESSAGE':
                            state.audioFilesURL.nonWorkingAfterHoursMessageUrl = '';
                            break;
                    }
                }
            })
            .addCase(setTempFileData.fulfilled, (state, action) => {
                state.tempFileData = action.payload;
            })
    },
});

export default msisdnSettingsSlice.reducer;
