import Twemoji from 'react-twemoji';

export const hexToEmoji = (hex: string, className = '') => {

    if (isNaN(parseInt(hex, 16))) {
        return String.fromCodePoint(parseInt('1F4DE', 16));
    }

    return (
        <Twemoji options={{  className: 'twemoji' }}>
            <span className={`flex items-center w-5 h-5 ${className}`}>
                {String.fromCodePoint(parseInt(hex, 16))}
            </span>
        </Twemoji>
    );
};
