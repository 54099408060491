import DialerHeader from "./components/DialerHeader";
import React, {useEffect, useState} from "react";
import Dialer from "./Dialer";
import Settings from "./Settings";
import AudioSettings from "./pages/AudioSettings";
import {MemoryRouter, Routes, Route} from 'react-router-dom';
import {RootState, useAppDispatch} from "../../store/store";
import {toggleDialerHidden} from "../../store/features/calls/dialerSlice";
import {useSelector} from "react-redux";
import { useTheme } from "next-themes";

import useMediaQuery from "../../utilis/screenWidthUtils.ts";


interface DialerWrapperProps {
    isInCalls: boolean;
}

export const DialerWrapper = ({isInCalls}: DialerWrapperProps) => {
    const dispatch = useAppDispatch()
    const isMobile = useMediaQuery('(max-width: 960px)');

    const incomingCall = useSelector((state: RootState) => state.dialer.incomingCall);
    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);

    const [hiddenDialerCalling, setHiddenDialerCalling] = useState(false);
    const { theme } = useTheme();

    useEffect(() => {
        if (incomingCall && isDialerHidden) {
            setHiddenDialerCalling(true);
        } else {
            setHiddenDialerCalling(false);
        }
    }, [incomingCall, isDialerHidden]);

    const showKeyboard = () => {
        dispatch(toggleDialerHidden());
    }



    return (
        <div className={`${isMobile ? 'hidden' : 'fixed right-0 top-0'}`}>

            <div onClick={() => {
                isDialerHidden &&
                showKeyboard();
            }}
                 className={` justify-center  border border-solid  mt-[97px] h-[calc(100vh-97px)]  ${isDialerHidden ? ' w-[80px] ' : ' w-[320px] '} ${hiddenDialerCalling ? ' !border-2 !border-primary-500' : 'min-w-320'} ${theme === 'dark' ? ' border-white bg-[#1C1E21]' : ' border-black bg-white'}`}>
                <MemoryRouter>
                    <DialerHeader/>

                    <React.StrictMode>
                        <Routes>
                            <Route path="/settings" element={<Settings/>}/>
                            <Route path="/settings/audio" element={<AudioSettings/>}/>
                        </Routes>
                    </React.StrictMode>
                </MemoryRouter>

                <Dialer isInCalls={isInCalls}/>


            </div>
        </div>
    )
}
