import {Avatar, Button, ChColumn, ChDropdown, ChInput, ChTooltip} from "ch3-ui-lib";
import {FormattedMessage, useIntl} from "react-intl";
import {useCallback, useEffect, useState} from "react";
import Header from "../../components/header/Header";
import {Link} from "react-router-dom";
import {ChDropdownGroup, Colors} from "../../utilis/types/Types";
import {fetchGroups, FetchGroupsResponse, loadMoreGroups} from "../../store/features/groups/groupsSlice";
import {useSelector} from "react-redux";
import {hexToEmoji} from "../../components/Emoji/emoji";
import {Msisdn, MsisdnSelectItem} from "../../store/features/Msisdns/msisdnsSlice";
import {RootState, useAppDispatch} from "../../store/store";
import noGroupsIcon from '../../assets/empty.svg';
import listEmpty from '../../assets/list-empty.svg';
import {debounce} from "lodash";
import {getFormatedPhoneNumber} from "../../utilis/msisdnUtilis";
import {

    getMsidnsByPhrase
} from "../../store/features/Searches/searchSlice";
import {ClosedDialerMargin, OpenDialerMargin} from "../../utilis/dialerWidthUtils.ts";

import { useTheme } from "next-themes";

import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import {loader} from "../../utilis/loader.tsx";


export default function GroupList() {
    const [loading, setLoading] = useState(true);

    const dispatch = useAppDispatch();

    const isMobile = useMediaQuery('(max-width: 960px)');

    const groupsData = useSelector((state: any) => state.groups.groups);
    const totalGroups = useSelector((state: any) => state.groups.totalCount);
    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);

    const [groups, setData] = useState(groupsData);
    const [phoneNumbersFilter, setPhoneNumbersFilter] = useState<MsisdnSelectItem[]>([]);
    const [phonePhrase, setPhonePhrase] = useState<string>('');
    const [phrase, setPhrase] = useState<string>('');
    const [ sort, setSort] = useState<any>({});
    const userMsisdns = useSelector((state: RootState) => state.search.msisdns);

    const intl = useIntl();
    const name = intl.formatMessage({id: 'groups.name'});
    const phoneNumber = intl.formatMessage({id: 'groups.users'});
    const sharedFor = intl.formatMessage({id: 'groups.phone-numbers'});
    const { theme } = useTheme();

    const handleDataChange = (value: string) => {
        if(value === '') {
            setPhrase('');
            return;
        }
        handlePhraseChange(value);
    };

    const handlePhraseChange = useCallback(
        debounce((phrase: string) => {
            setPhrase(phrase);
        }, 1000), // 1 second delay
        []
    );
    const handleDataSortChange = (order:string, property:string) => {
        setSort({
            order,
            property
        });
    };


    useEffect(() => {
        setData(groupsData)
    }, [groupsData]);

    const phoneNumbers = userMsisdns?.map((msisdn:any) => (
        {
            label:<div className='flex'> <div className='mr-2'>{hexToEmoji(msisdn.icon)}</div> { msisdn.label }</div>,
            value: msisdn,
            key: msisdn.msisdnId}));


    const fetchMsisdns = async () => {
        await dispatch(getMsidnsByPhrase(phonePhrase));
    };
    const debouncedFetchMsisdns = useCallback(debounce(fetchMsisdns, 1000), [phonePhrase, dispatch]);
    useEffect(() => {
        if(phonePhrase.length < 3) return;
        debouncedFetchMsisdns();
        return () => {
            debouncedFetchMsisdns.cancel();
        }

    }, [phonePhrase, dispatch]);

    const handlePhoneNumberSelect = (value: MsisdnSelectItem) => {
        setPhoneNumbersFilter((prev: MsisdnSelectItem[]) => {
            const exists = prev.some(item => item === value);
            if (exists) {
                return prev.filter((item) => item !== value);
            } else {
                return [...prev, value];
            }
        });
    }
    const dropdownGroup: ChDropdownGroup[] = [{
        items: phoneNumbers,
        type: 'checkbox',
        callback: (value: any) => {
            handlePhoneNumberSelect(value)
        }
    }]

    const filterButton = <Button size={'small'} className='mb-6' label='Phone numbers' leftIcon='filter_list' buttonType='secondary'/>


    const createNewContactButton = () => {
        return (
            <Link to='/groups/details/new'>
                <Button buttonType='primary' label='Create new group'/>
            </Link>
        )
    }

    const loadMore = () => {
        dispatch(loadMoreGroups({phrase,lastName: groupsData.at(-1).group.name , lastGroupId: groupsData.at(-1).group.groupId}));
    }

    useEffect(() => {
        setData(groupsData);
    }, [groupsData]);

    const fetchInitialData = async () => {
        await dispatch(getMsidnsByPhrase(''));
    };

    useEffect(() => {
        fetchInitialData();
    }, [dispatch]);

    useEffect(() => {
        const msisdns = phoneNumbersFilter.map((phoneNumber: MsisdnSelectItem) => phoneNumber.msisdn);
        const order = sort?.order;
        const sortBy = sort?.property;
        const fetchData = async () => {
            await dispatch(fetchGroups({phrase, msisdns, order, sortBy}));

        }
        setLoading(false);

        fetchData();

    }, [dispatch, phrase, phoneNumbersFilter, sort]);
    return (
        <>
        {loading &&
            loader
        }
            {!loading &&
        <div className='w-full'>

            <Header header='Groups' icon={'1F46A'} button={createNewContactButton()}/>
            <div className={`  ${isMobile ? ' px-4' : isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>
                <div className={`flex justify-between ${isMobile ? ' flex-wrap mb-8 flex-col' : ' mx-8 no-wrap mb-0 flex-row'}`}>
                    <ChDropdown trigger={filterButton} type={'search'} dropdownGroup={dropdownGroup}  onPhraseChange={(value: string) => setPhonePhrase(value)} selected={phoneNumbersFilter} ></ChDropdown>
                    <ChInput placeholder={'Search'} className={`${isMobile ? ' ' : ' min-w-[375px]'}`}  prefixIcon={'search'} callback={handleDataChange}/>
                </div>

                <div className={`h-[calc(100vh-300px)] mr-4 overflow-y-auto mb-4  ${isMobile ? ' ' : ' pr-4 pl-8'}`}>
                    <p className='text-lg font-semibold px-4 py-5 border-x border-t border-black'><FormattedMessage

                        id='groups.list'/>
                        <span className={`border rounded-sm px-2 text-xs mx-2 py-1 ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
        {totalGroups}
        </span>

                    </p>
                    {Boolean(groups.length) && <table className={` w-full border border-black ${isMobile ? ' overflow-x-scroll' : ' '}`}>

                        <thead>
                        <tr className={`border-b ${theme === 'dark' ? ' border-white' : ' border-black'}`} >
                            <th className='pl-4 py-3 w-full'
                            ><ChColumn propertyName='NAME' callback={handleDataSortChange} label={name}/></th>
                            <th className='min-w-[300px] '
                            ><ChColumn propertyName='MSISDN' callback={handleDataSortChange} label={phoneNumber}/></th>
                            <th className='min-w-[200px]'
                            ><ChColumn propertyName='USERS' callback={handleDataSortChange} label={sharedFor}/></th>
                        </tr>
                        </thead>
                        <tbody>
                        {groups.map((groupData: FetchGroupsResponse, index: number) => {
                                return (
                                    <tr className={`border   ${theme === 'dark' ? ' border-white hover:bg-grey-700' : ' border-black hover:bg-grey-50'}`} key={index}>
                                        <Link to={`/groups/details/${groupData.group.groupId}`}
                                              className='no-underline block w-full'>

                                            <td className='flex gap-3 font-semibold  items-center pl-4 py-4'><Avatar
                                                size="md" type='icon' icon={'home'}
                                                color={groupData.group.iconColor}
                                            />{groupData.group.name}
                                            </td>
                                        </Link>


                                        <td>
                                            <div className=" justify-start items-center flex">
                                                {
                                                    groupData.users?.map((user, index) => (
                                                        <ChTooltip

                                                            trigger={<div  style={{right: `${index * 20}px`}}
                                                                                 className={`p-[2px] bg-white relative`}>
                                                            <Avatar key={index} size="md" type='initials'
                                                                    color={user.iconColor ?? Colors.auto}
                                                                    name={user.firstName + ' ' + user.lastName}/>
                                                        </div>} content={user.firstName + ' ' + user.lastName} theme={'dark'}></ChTooltip>

                                                    ))}
                                            </div>
                                        </td>
                                        <td className={'flex gap-3 mr-4 items-center'}>
                                            {groupData.msisdns.slice(0, 2).map((el: Msisdn) => {
                                                return <div className='flex items-center'>
                                                    <div className='text-xl mr-2 '>{hexToEmoji(el.icon)}</div>
                                                    {el.label ?? getFormatedPhoneNumber(el.msisdn)}</div>
                                            })}
                                            {
                                                groupData.msisdns.length > 2 && `+${groupData.msisdns.length - 2}`
                                            }
                                        </td>

                                    </tr>
                                )
                            }
                        )}


                        </tbody>

                    </table>}
                    {groups.length === 0 && Boolean(totalGroups) &&
                        <div className={`flex text-center  justify-center  border ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                            <div className='w-[450px]  m-14'>
                                <div className='flex  justify-center w-full'>
                                    <img className='m-4' src={noGroupsIcon}/>
                                </div>

                                <h1 className='font-bold'>
                                    No groups found
                                </h1>
                                <div className='mb-6 mt-2'>
                                    No groups found matching your search. Please double-check your criteria or consider
                                    adding new groups to expand your network!
                                </div>
                                <div className='flex justify-center'>
                                    <Link to='/groups/details/new'>
                                        <Button shadow leftIcon='add' buttonType='primary' label='Create new group'/>
                                    </Link>

                                </div>
                            </div>

                        </div>

                    }
                    {!totalGroups &&
                        <div className={`flex text-center  justify-center  border ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                            <div className='w-[450px]  m-14'>
                                <div className='flex  justify-center w-full'>
                                    <img className='m-4' src={listEmpty}/>
                                </div>

                                <h1 className='font-bold'>
                                    No groups yet!
                                </h1>
                                <div className='mb-6 mt-2'>
                                    Click below to add one now.
                                </div>
                                <div className='flex justify-center'>
                                    <Link to='/groups/details/new'>
                                        <Button shadow leftIcon='add' buttonType='primary' label='Create new'/>
                                    </Link>
                                </div>
                            </div>

                        </div>

                    }
                </div>
                {(Boolean(groups.length) && groups.length == totalGroups) && <div className='flex justify-center my-4'>
                    <Button onClick={loadMore} leftIcon={'more_horiz'} size='small' buttonType='secondary' label='Load more'/>

                </div>}
            </div>

        </div>
        }
        </>

    )
}
