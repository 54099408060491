import { createSlice } from '@reduxjs/toolkit';
import {RootState} from "../../store";


type ToastType = 'info' | 'success' | 'warning' | 'error';
interface ToastState {
    title: string;
    description: string;
    type: ToastType;
    isOpen: boolean;
}

const initialState: ToastState = {
    title: '',
    description: '',
    type: 'info',
    isOpen: false,
};

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        showToast: (state, action) => {
            state.title = action.payload.title;
            state.description = action.payload.description;
            state.type = action.payload.type;
            state.isOpen = true;
        },
        hideToast: (state) => {
            state.isOpen = false;
        },
    },
});

export const { showToast, hideToast } = toastSlice.actions;
export const getToast = (state: RootState) =>
    state.toast;
export default toastSlice.reducer;
