import { forwardRef } from "react";
import { PhoneInput } from "react-international-phone";
import { useTheme } from "next-themes";

interface PhoneNumberInputProps {
    phoneNumber: string;
    onNumberChange: (val: PhoneNumberData) => void;
    name?: string;
    label?: string;
    index?: number;
    errors?: any;
    noBorder?: boolean;
    placeholder?: string;
    onBlur?: () => void;
}

export interface PhoneNumberData {
    phone: string;
    name: string;
    index?: number;
}

const PhoneNumberInput = forwardRef<HTMLInputElement, PhoneNumberInputProps>(({
                                                                                  phoneNumber, onNumberChange, index, errors, noBorder, label, name, onBlur
                                                                              }, ref:any) => {
    const data = index ? { name: 'phoneNumbers', index } : { name: 'phoneNumber' };
    const { theme } = useTheme();

    return (
        <div className={`w-full ${theme === 'dark' ? ' text-white ' : ' text-black '}`}>
            {Boolean(label) && <span className={`text-sm ${theme === 'dark' ? ' text-white ' : ' text-black '}`}>{label}</span>}
            <PhoneInput
                name={name}
                onBlur={onBlur}
                className={`flex-grow w-full`}
                disableDialCodePrefill={true}
                inputClassName={`!w-full !border-x-0 !border-t-0 !border-b !border-black !box-border !rounded-none focus:!outline-none !outline-none focus:!ring-0 focus:!ring-offset-0 !border-black !bg-transparent ${theme === 'dark' ? ' !text-white !border-white' : ' !text-black '}` + (errors?.length ? '!border-red-500 border-b' : '') + (noBorder ? '!border-none' : '')}
                countrySelectorStyleProps={{
                    buttonClassName: '!border-x-0 !mr-4 !border-t-0 !border-b !border !border-black !bg-transparent !rounded-none ' + (errors?.length ? '!border-red-500 border-b' : '' + (noBorder ? '!border-none' : '')),
                    dropdownStyleProps: {
                        className: 'border border-black rounded-sm !max-h-[300px]',
                        style: { outline: 0 },
                        listItemClassName: '!py-2'
                    }
                }}
                defaultCountry={navigator.language.slice(0, 2) ?? 'us'}
                placeholder={'Type phone number to call'}
                value={phoneNumber}
                onChange={(phone) => onNumberChange({ phone, ...data })}
                ref={ref}
            />
            {Boolean(errors?.length) && <div className={'pt-2 text-red-500 text-sm'}>{errors ? errors : 'Phone number is not valid'}</div>}
        </div>
    );
});

export default PhoneNumberInput;
