import {Button, ChSearch} from "ch3-ui-lib";
import { selectorItem} from "../../utilis/types/Types";
import {useState} from "react";

interface AddContactModalProps {
    data: any[];
    setData: Function;
    type: string;
    users?: selectorItem[];
    msisdns?: selectorItem[];
}

const AddUserNumberModal = ({data, setData, type, users, msisdns}: AddContactModalProps) => {

    const items = users ? users : msisdns;
    const [selected, setSelected] = useState(data);
    const [list, setList] = useState<selectorItem[]>(items ?? []);

    const handleChange = (item: selectorItem) => {

        const updatedList = list.filter((i) => i.value !== item.value);
        setList(updatedList);

        if(selected?.length) {
            setSelected( [...selected, item])
            setData([...selected, item]);
        } else {
            setSelected([item]);
            setData([item]);
        }
    };

    const handleRemove = (indexToRemove: number) => {
        const removedItem = selected[indexToRemove];
        const updatedSelected = selected.filter((_, index) => index !== indexToRemove);

        setSelected(updatedSelected);
        setData(updatedSelected);


        setList([...list, removedItem]);
    };

    return (
        <div className={'w-[400px]'}>
            <div className='text-grey-500 text-sm pb-5'>
                {type === 'USERS' ? 'The following users are in this group' : 'The following phone numbers are assigned to this group'}
            </div>
            <div>
                <ChSearch onChange={(item) => handleChange(item)} contentWidth='400px' type={'text'} disabled={false} value={''} fit
                          label={type === 'USERS' ? 'Choose team member' : 'Choose phone number'} prefixIcon='search'
                          placeholder='Search' items={list}></ChSearch>
            </div>
            <div className='grid font-semibold gap-3 mb-8'>
                {Boolean(selected?.length) && <>
                    <div className={'p-4 text-sm'}>
                        {type === 'USERS' ? 'USERS' : 'PHONE NUMBERS'}
                    </div>
                    <div>
                        {selected.map((obj, index: number) => (
                            <div className='flex justify-between items-center' key={index}>
                                <div className={'flex items-center gap-3'}>
                                    {Boolean(type === 'USERS') ?
                                        (<>
                                            <div>{obj.prefixElement}</div>
                                            <div>
                                                {obj.label}
                                            </div>
                                        </>) :
                                        (<>
                                            <div>{obj.prefixElement}</div>
                                                <div>
                                                    {obj.label}
                                                </div>
                                        </>
                                        )}
                                </div>
                                <Button label={'Remove'} buttonType={'textError'}
                                        onClick={() => handleRemove(index)}/>
                            </div>
                        ))}
                    </div>
                </>
                }
            </div>
        </div>
    )

}
export default AddUserNumberModal;
