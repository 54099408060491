import {Avatar} from "ch3-ui-lib";
import {hexToEmoji} from "../Emoji/emoji";
import {formattedTime} from "../../utilis/timeUtilis";
import {CallStateEnum} from "./Dialer";
import {isPossiblePhoneNumber} from "libphonenumber-js";
import {useEffect, useState} from "react";
import {getFormatedPhoneNumber} from "../../utilis/msisdnUtilis";
import { useTheme } from "next-themes";

// interface CallButtonProps {
//     icon: string;
//     label: string;
//
// }

// const callButtons: CallButtonProps[] = [
//     {icon: "dialpad", label: "keypad"},
//     {icon: "mic_off", label: "mute"},
//     {icon: "volume_up", label: "speaker"},
//     {icon: "pause", label: "hold"},
//     {icon: "record_voice_over", label: "record"},
//     {icon: "person_add", label: "add user"}
// ];

export interface CallProps {
    callState: CallStateEnum;
    timer: number;
    isIncomingCall: boolean;
    phoneNumber: string;
    callFrom: string;
    isDialerHidden: boolean;
}
// const CallButton = ({icon}: CallButtonProps) => (
//     <Button className='p-[10px]' leftIcon={icon} buttonType={'secondary'}/>
// );

const Call = ({ callState, timer, isIncomingCall, callFrom, isDialerHidden }: CallProps) => {
    const [contact, setContact] = useState<string | undefined>(undefined);
    const [isPhoneNumber, setIsPhoneNumber] = useState<boolean>(false);
    const { theme } = useTheme();

    useEffect(() => {

        if(callFrom && isPossiblePhoneNumber(callFrom)) {
            const formattedPhoneNumber = getFormatedPhoneNumber(Number(callFrom.slice(1)), false);
            setContact(formattedPhoneNumber);
            setIsPhoneNumber(true);
        } else {
            setContact(callFrom);
            setIsPhoneNumber(false);
        }
    }, [callFrom]);

    return (

        <div>  {   !isDialerHidden && callState == 'CONNECTING' &&
            <div className={`flex flex-col pb-8 max-w-xs  border-x border-t ${theme === 'dark' ? ' border-white bg-[#1C1E21]' : ' border-black bg-white'}`}>
                <main className="flex flex-col items-center px-7 pb-12 w-full font-semibold">
                    <Avatar size={'xxl'} type={!isPhoneNumber ? 'initials' : 'default'} color={'auto'} name={contact}
                            className='pb-4 px-[130px] pt-7'/>
                    <div className="mt-6 text-base leading-6 text-neutral-700">Calling...</div>
                    <h1 className="flex justify-center self-stretch mt-3 text-2.5xl text-black text-center">{contact}</h1>
                </main>
                {/*<section*/}
                {/*    className="flex flex-col flex-wrap justify-center content-start self-center px-6  mt-12 w-full max-w-[277px]">*/}
                {/*    <div className="flex gap-5">*/}
                {/*        {callButtons.slice(0, 3).map((button) => (*/}
                {/*            <div>*/}
                {/*                <CallButton key={button.label} icon={button.icon} label={button.label}/>*/}
                {/*                <div className='mt-2 text-xs text-center'>{button.label}</div>*/}
                {/*            </div>*/}

                {/*        ))}*/}

                {/*    </div>*/}
                {/*    <div className="flex gap-5 mt-5">*/}
                {/*        {callButtons.slice(3).map((button) => (*/}
                {/*            <div>*/}
                {/*                <CallButton key={button.label} icon={button.icon} label={button.label}/>*/}
                {/*                <div className='mt-2 text-xs text-center'>{button.label}</div>*/}
                {/*            </div>*/}
                {/*        ))}*/}
                {/*    </div>*/}
                {/*</section>*/}
            </div>

        }
            {callState === 'IN_CALL'  &&
                <div className="flex flex-col   max-w-xs bg-white">
                    <main className="flex flex-col items-center px-8 pb-12 w-full font-semibold border-x border-t border-black">
                        <Avatar size={'xxl'}  type={!isPhoneNumber ? 'initials' : 'default'} color={'auto'} name={contact}
                                className='pb-4 px-[130px] pt-7'/>
                        <div className="mt-6 text-base leading-6 text-neutral-700"></div>
                        <h1 className=" text-2.5xl text-black text-center">{contact}</h1>
                        <div className="text-[16px]">
                            <div className="flex justify-center flex-col items-center">
                            {hexToEmoji('1F551')}{formattedTime(timer)} 
                            </div>
                            </div>
                    </main>
                    {/*<section*/}
                    {/*    className="flex flex-col flex-wrap justify-center content-start self-center px-6  mt-12 w-full max-w-[277px]">*/}
                    {/*    <div className="flex gap-5">*/}
                    {/*        {callButtons.slice(0, 3).map((button) => (*/}
                    {/*            <div>*/}
                    {/*                <CallButton key={button.label} icon={button.icon} label={button.label}/>*/}
                    {/*                <div className='mt-2 text-xs text-center'>{button.label}</div>*/}
                    {/*            </div>*/}

                    {/*        ))}*/}

                    {/*    </div>*/}
                    {/*    <div className="flex gap-5 mt-5">*/}
                    {/*        {callButtons.slice(3).map((button) => (*/}
                    {/*            <div>*/}
                    {/*                <CallButton key={button.label} icon={button.icon} label={button.label}/>*/}
                    {/*                <div className='mt-2 text-xs text-center'>{button.label}</div>*/}
                    {/*            </div>*/}
                    {/*        ))}*/}
                    {/*    </div>*/}
                    {/*</section>*/}
                </div>

            }
            {  !isDialerHidden && callState === 'IDLE' && isIncomingCall &&
                <div className="flex flex-col pb-8 max-w-xs bg-white border-x border-t border-black">
                    <main className="flex flex-col items-center px-8 w-full font-semibold">
                        <Avatar size={'xxl'} type={!isPhoneNumber ? 'initials' : 'default'} color={'auto'} name={contact}
                                className='pb-4 px-[130px] pt-7'/>
                        <div className="mt-6 text-base leading-6 text-neutral-700"></div>
                        <div className='font-base font-normal'>Call from</div>
                        <h1 className=" mt-3 text-2.5xl text-black text-center">{contact}</h1>
                    </main>
                    <section
                        className="flex flex-col flex-wrap justify-center content-start self-center px-6  mt-4 w-full max-w-[277px]">

                    </section>
                </div>

            }
        </div>



    );
}
export default Call;
