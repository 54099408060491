import {Avatar, Button, ChSearch} from "ch3-ui-lib";
import {Colors, selectorItem} from "../../utilis/types/Types";
import {User} from "../../store/features/Users/usersSlice";
import {Group} from "../../store/features/groups/groupsSlice";
import {useCallback, useEffect, useState} from "react";
import {RootState, useAppDispatch} from "../../store/store";
import {useSelector} from "react-redux";
import {debounce} from "lodash";
import {fetchUsers, getGroupsByPhrase} from "../../store/features/Searches/searchSlice";

interface AddContactModalProps {
    setUsers: Function;
    setGroups: Function;
    selectedUsers: User[];
    selectedGroups: Group[];
}
const AddContactModal = (({ selectedUsers, selectedGroups, setUsers, setGroups}:AddContactModalProps) => {

    const dispatch = useAppDispatch();
    const [phrase, setPhrase] = useState('');

    const groupsData = useSelector((state: RootState) => state.search.searchGroupUsers.sharedForGroups)

    const groups = groupsData?.map((group: any) => ({
        id: group?.groupId,
        iconColor: group?.iconColor,
        name: group?.name})) as Group[];

    const users = useSelector((state: RootState) => state.search.searchGroupUsers.sharedForUsers) as User[];



    const [selectedUserList, setSelectedUserList] = useState(selectedUsers);
    const [selectedGroupList, setSelectedGroupList] = useState(selectedGroups);

    const filteredUsers = users.filter(user => !selectedUserList.find(selectedUser => selectedUser.userId === user.userId));
    const filteredGroups = groups.filter(group => !selectedGroupList.find(selectedGroup => selectedGroup.id === group.id));


    const userItems = filteredUsers?.map((user: User) => ({label: user.firstName + ' ' + user.lastName, value: user.userId.toString(),key:`user${user.userId}`, prefixElement: <div className='ml-2'><Avatar size={'md'} type={'initials'} color={user.iconColor ?? Colors.auto} name={user.firstName + ' ' + user.lastName}/></div>}));
    const groupItems = filteredGroups?.map((group: Group) => ({label: group.name, value: group.id?.toString(), key:`group${group.id}`, prefixElement: <div className='ml-2'><Avatar size={'md'} icon={'home'} type={'icon'} color={Colors.orange} name={group.name}/></div>}));


    let list = [...userItems, ...groupItems] as selectorItem[];

    const handlePhraseChange = useCallback(
        debounce((phrase: string) => {
            setPhrase(phrase);
        }, 500),
        []
    );
    useEffect(() => {
        const fetchData = async () => {
            if(phrase.length > 2) {
                await dispatch(getGroupsByPhrase({phrase}));
                await dispatch(fetchUsers({phrase}));
            }
        }
        fetchData();

    }, [phrase]);

    const handleChange = (item: selectorItem) => {

       list = list.filter((i) => i.value !== item.value);
        if(item.key.includes('user')) {
            setSelectedUserList((prevUsers: User[]) => [...prevUsers, users.find((user: User) => user.userId == Number(item.value)) as User]);
                setUsers([...selectedUserList, users.find((user: User) => user.userId == Number(item.value)) ] );

        } else {
           setSelectedGroupList((prevGroups: Group[]) => {
                return [...prevGroups, groups.find((group: Group ) => group.id == Number(item.value)) as Group]
           });
           setGroups([...selectedGroupList, groups.find((group: Group ) => group.id == Number(item.value))] );
        }
        list = list.filter((i) => i.value !== item.value);

    };

    const handleRemove = (indexToRemove: number, type: string) => {
        if (type === 'user') {
            setSelectedUserList((prevUsers: User[]) => prevUsers.filter((_, index: number) => index !== indexToRemove));

            const user = selectedUserList[indexToRemove];
            list  =[...list, {
                label: user.firstName + ' ' + user.lastName,
                value: user.userId,
                key:`user${user.userId}`,
                prefixElement: <div className='ml-2'><Avatar size={'md'} type={'initials'} color={user.iconColor ?? Colors.auto} name={user.firstName + ' ' + user.lastName}/></div>}] as selectorItem[];
            setUsers((prevUsers: User[]) => prevUsers.filter((_, index: number) => index !== indexToRemove));
        } else if (type === 'group') {
            const group = selectedGroupList[indexToRemove];
            list  = [...list, {
                label: group.name,
                value: group.id,
                key:`group${group.id}`,
                prefixElement: <div className='ml-2'><Avatar size={'md'} icon={'home'} type={'icon'} color={Colors.orange} name={group.name}/></div>}] as selectorItem[];
            setSelectedGroupList((prevGroups: Group[]) => prevGroups.filter((_, index: number) => index !== indexToRemove));
            setGroups((prevGroups: Group[]) => prevGroups.filter((_, index: number) => index !== indexToRemove));

        }
    };

    return (
        <div className="w-[400px]">
            <div className='text-grey-500 text-sm pb-5'>
                The following users have access to this contact
            </div>
            <div>
                <ChSearch contentWidth='300px' onInputChange={handlePhraseChange} onChange={(item) => handleChange(item)} type={'text'} disabled={false} value={''} fit
                          label='Add users or group' prefixIcon='search' placeholder='search' items={list}></ChSearch>
            </div>
            <div className='grid font-semibold gap-3 mb-8'>
                {Boolean(selectedUserList.length) && <>
                    <div className={'p-4 text-sm'}>
                        USERS
                    </div>
                    <div>
                        {selectedUserList?.map((user, index:number) => (
                            <div className='flex justify-between items-center' key={index}>
                                <div className={'flex gap-3 items-center'}>
                                    <Avatar size={'md'} type={'initials'} color={user.iconColor ?? Colors.auto} name={user.firstName + ' ' + user.lastName}/>
                                    <div>
                                        {user.firstName + ' ' + user.lastName}
                                    </div>
                                </div>
                                <Button label={'Remove'} buttonType={'textError'}
                                        onClick={() => handleRemove(index, 'user')}/>
                            </div>
                        ))}
                    </div>
                </>
                }
                {Boolean(selectedGroupList?.length) &&
                    <>
                        <div className={'p-4 text-sm'}>
                            GROUPS
                        </div>
                        <div>
                            {selectedGroupList?.map((group, index:number) => (
                                <div className='flex justify-between items-center' key={index}>

                                    <div className={'flex gap-3 items-center'}>
                                        <Avatar size={'md'} type={'icon'} color={group.iconColor  ?? Colors.orange} icon={'home'}/>
                                        <div>
                                            {group.name }
                                        </div>
                                    </div>
                                    <Button label={'Remove'} buttonType={'textError'}
                                            onClick={() => handleRemove(index, 'group')}/>

                                </div>
                            ))}
                        </div>
                    </>
                }
            </div>
        </div>
    )
});
export default AddContactModal;
