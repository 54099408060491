import {Icon} from "ch3-ui-lib";
import {Link} from "react-router-dom";


const sections = [{
    name: 'Audio',
    elements: [{
        label: 'Audio Settings',
        link: '/settings/audio'
    }]
}];
const Settings = () => {
    return (

        <div className="flex relative h-full flex-col max-w-xs bg-white z-20 bg-white">
            <header className="flex justify-between p-4 text-2xl font-semibold leading-8 text-black whitespace-nowrap">
                <h5>Settings</h5>
                <Link to={'/'}> <Icon iconName={'close'} className='cursor-pointer'/></Link>
            </header>
            {sections.map((section) => {

                return (
                    <>
                        <h2 className="px-5 mt-4 text-xs font-semibold tracking-wider leading-5 text-black uppercase">
                            {section.name}
                        </h2>
                        {section.elements.map((element) => (
                            <Link to={element.link}>
                                <div
                                    className="flex items-center justify-between gap-3 self-center px-5 mt-2 w-full text-base font-semibold leading-6 text-black max-w-[328px]">
                                    <span>{element.label}</span>
                                    <Icon iconName={'chevron_right'}/>
                                </div>
                            </Link>
                        ))}
                    </>


                )
            })}
        </div>
    );
};

export default Settings;
