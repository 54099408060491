import {Button, ChModal, ChRadio, Icon} from "ch3-ui-lib";
import {useEffect, useRef, useState} from "react";
import ForwardCall from "./Modal/ForwardCall";
import {isValidPhoneNumber, parsePhoneNumber} from "libphonenumber-js";
import {Link, useNavigate, useParams} from "react-router-dom";
import {FlagImage} from "react-international-phone";
import {getFlag} from "../../utilis/msisdnUtilis";
import {
    AudioFilesURL,
    CallRulesDTO,
    getMsisdnSettingsCallRules, getRecording,
    updateMsisdnSettingsCallRules,
} from "../../store/features/MsisdnSettings/msisdnSettingsSlice";
import {RootState, useAppDispatch} from "../../store/store";
import {useSelector} from "react-redux";
import WelcomeMessage from "./Modal/WelcomeMessage";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";

import { useTheme } from "next-themes";
import {ClosedDialerMargin, OpenDialerMargin} from "../../utilis/dialerWidthUtils.ts";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";


const CallRules = () => {

    const dispatch = useAppDispatch();
    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 960px)');
    const [isValidMsisdn, setIsValidMsisdn] = useState<boolean>(false);
    const [forwardMsisdnWorkingDraft, setForwardMsisdnWorkingDraft] = useState<string>('');
    const [forwardMsisdnNotWorkingDraft, setForwardMsisdnNotWorkingDraft] = useState<string>('');

    const callRules = useSelector((state: RootState) => state.msisdnSettings.callRules );
    const tempFile = useSelector((state: RootState) => state.msisdnSettings.tempFileData);
    const audioURLs = useSelector((state: RootState) => state.msisdnSettings.audioFilesURL);

    const { theme } = useTheme();

    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);



    const [formData, setFormData] = useState({...callRules as CallRulesDTO});


    const [audioURL, setAudioURL] = useState<AudioFilesURL>({
        workingWelcomeMessageUrl: callRules?.workingWelcomeMessageUrl ,
        nonWorkingWelcomeMessageUrl: callRules?.nonWorkingWelcomeMessageUrl,
        nonWorkingAfterHoursMessageUrl: callRules?.nonWorkingAfterHoursMessageUrl
    });
    useEffect(() => {
        setAudioURL({
            workingWelcomeMessageUrl: audioURLs?.workingWelcomeMessageUrl,
            nonWorkingWelcomeMessageUrl: audioURLs?.nonWorkingWelcomeMessageUrl,
            nonWorkingAfterHoursMessageUrl: audioURLs?.nonWorkingAfterHoursMessageUrl
        });
    }, [audioURLs]);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getMsisdnSettingsCallRules(id));
            await dispatch(getRecording({id, type:'AFTER_HOURS_MESSAGE'}));
            await dispatch(getRecording({id, type:'WORKING_HOURS_WELCOME_MESSAGE'}));
        }
        fetchData();
    }, [id]);

    useEffect(() => {
        setFormData(callRules as CallRulesDTO);

    }, [callRules]);

    const  callOptions = {
        welcomeMessageWorking: {
            label: 'Welcome message',
            description: 'Create a friendly greeting for callers to enhance their experience.',
            url: audioURL.workingWelcomeMessageUrl,
            isEnabled: formData.workingWelcomeMessageEnabled,
        },
        afterHoursMessage: {
label: 'After hours message',
            description: 'Create a friendly greeting for callers to enhance their experience.',
            url: audioURL.nonWorkingAfterHoursMessageUrl,
            isEnabled: formData.nonWorkingAfterHoursMessageEnabled,
        },
        forwardWorking: {
            label: 'Forward call',
            description: 'Calls will be forwarded to the specified phone number.',
            url: audioURL.workingWelcomeMessageUrl,
            isEnabled: formData.workingForwardEnabled,
        },
        forwardNonWorking: {
            label: 'Forward call',
            description: 'Calls will be forwarded to the specified phone number.',
            url: audioURL.workingWelcomeMessageUrl,
            isEnabled: formData.nonWorkingForwardEnabled,
        },
        receiveIncomingCall: {
            label: 'Receive incoming calls',
            description: 'You will answer the incoming call.',
        },
        dontAcceptCals: {
label: 'Don’t accept calls',
            description: 'Callers receive a busy signal.',
        }

    }


    const welcomeMessageWorkingRef = useRef<{ onSubmit: () => void, openModal: () => void }>(null);
    const afterHoursMessageRef = useRef<{ onSubmit: () => void, openModal: () => void }>(null);

    const forwardCallWorkingRef = useRef<{ onSubmit: () => void, openModal: () => void }>(null);
    const forwardCallNonWorkingRef = useRef<{ onSubmit: () => void, openModal: () => void }>(null);



    const getCallOption = (label:string, description: string, isEnabled: boolean, type: string, ref:any, element: any, callback?:  () => void, url?:string | undefined) => {
    const item = {
        id: type,
        value: type,
        label: label,
        description: description,
    }
        return(
        <div
            id={type}
            className={`p-4 border border-black border-dotted mt-2 hover:border-primary-500 hover:border-solid cursor-pointer`}>
            <div className='flex justify-between items-start'>
            <ChRadio
                singleRadio={true}
                radioItem={item}
                alignLeft={false}
                callback={() => {
                    if(!isEnabled && ref && !url) {
                        ref.current?.openModal()
                    }
                    if (callback) {
                        callback()
                    }

                }}
                value={isEnabled ? type : false}
            />
                {isEnabled && element && <Button size='small' buttonType='textSecondary'
                         onClick={(e) => {
                             ref.current?.openModal()
                                e.stopPropagation()
                         }
                } label='Change'></Button>}
            </div>
            {isEnabled && element}
        </div>

        )
    }

    const getAudioPlayer = (url: string | undefined, isEnabled: boolean) => {
        return (
             url &&
        <div className='mt-4' onClick={event => event.stopPropagation()}>
            <AudioPlayer disabled={!isEnabled} src={url}/>
        </div>

        )
    }

    const getForwardNumber = (phoneNumber: number | null) => {
        return (
            phoneNumber &&
        <div className='mt-4 text-sm font-medium'>
            Phone number:
            <div className='flex bg-grey-50 text-gray-400 p-3 items-center gap-2 text-sm'>
                <FlagImage className='w-4 h-4'
                           iso2={getFlag(phoneNumber) ?? 'us'}/>
                <div>{parsePhoneNumber(`+${phoneNumber}`).formatInternational()}</div>
            </div>
        </div>) ;
    }
    const nonWorkingDontAcceptCallsChange = () => {

        setFormData(prevState =>
            ({
                ...prevState,
                nonWorkingDontAcceptCalls: true,
                nonWorkingAfterHoursMessageEnabled: false,
                nonWorkingForwardEnabled: false
            }));


    }
    const receiveIncomingCallChange = () => {
        setFormData(prevState =>
            ({
                ...prevState,
                workingForwardEnabled: false,
                workingWelcomeMessageEnabled: false
            }));
    }
const enableWelcomeMessage = () => {
    setFormData(prevState =>
        ({
            ...prevState,
            workingWelcomeMessageEnabled: true,
            workingForwardEnabled: false
        }));
}
const enableAfterHoursMessage = () => {
    setFormData(prevState =>
        ({
            ...prevState,
            nonWorkingAfterHoursMessageEnabled: true,
            nonWorkingForwardEnabled: false,
            nonWorkingDontAcceptCalls: false
        }));
}


    const welcomeMessageAudioPlayer = getAudioPlayer(audioURL.workingWelcomeMessageUrl, formData.workingWelcomeMessageEnabled);
    const welcomeMessageTrigger = getCallOption(callOptions.welcomeMessageWorking.label, callOptions.welcomeMessageWorking.description, callOptions.welcomeMessageWorking.isEnabled, 'welcomeMessageWorking', welcomeMessageWorkingRef, welcomeMessageAudioPlayer,enableWelcomeMessage, callOptions.welcomeMessageWorking.url);


    const forwardCallNumberElement = getForwardNumber(formData.workingForwardMsisdn);
    const forwardCallWorkingTrigger = getCallOption(callOptions.forwardWorking.label, callOptions.forwardWorking.description, callOptions.forwardWorking.isEnabled, 'forwardWorking', forwardCallWorkingRef, forwardCallNumberElement)

    const forwardCallNonWorkingElement = getForwardNumber(formData.nonWorkingForwardMsisdn);
    const forwardCallNonWorkingTrigger = getCallOption(callOptions.forwardNonWorking.label, callOptions.forwardNonWorking.description, callOptions.forwardNonWorking.isEnabled, 'forwardNonWorking', forwardCallNonWorkingRef, forwardCallNonWorkingElement)

    const isReceiveIncomingCallEnabled = !formData.workingForwardEnabled &&  !formData.workingWelcomeMessageEnabled;
    const receiveIncomingCallTrigger = getCallOption(callOptions.receiveIncomingCall.label, callOptions.receiveIncomingCall.description, isReceiveIncomingCallEnabled, 'receiveIncomingCall', undefined, undefined, receiveIncomingCallChange)

    const afterHoursMessageAudioPlayer = getAudioPlayer(audioURL.nonWorkingAfterHoursMessageUrl, formData.nonWorkingAfterHoursMessageEnabled);
    const afterHoursMessageTrigger = getCallOption(callOptions.afterHoursMessage.label, callOptions.afterHoursMessage.description, callOptions.afterHoursMessage.isEnabled, 'afterHoursMessage', afterHoursMessageRef, afterHoursMessageAudioPlayer,enableAfterHoursMessage, callOptions.afterHoursMessage.url);

    const dontAcceptCallsElement = getCallOption(callOptions.dontAcceptCals.label, callOptions.dontAcceptCals.description, formData.nonWorkingDontAcceptCalls, 'dontAcceptCalls', undefined, undefined, nonWorkingDontAcceptCallsChange);

    const checkIfIsValidPhoneNumber = (val: string, type: string) => {
        if (val.length == 0) {
            setIsValidMsisdn(true);
            setForwardMsisdnWorkingDraft('');
            return true;
        }
        if (type == 'working') {

            try {
                const isValid = isValidPhoneNumber(val);
                setIsValidMsisdn(isValid);
                setForwardMsisdnWorkingDraft(val.slice(1));
                return isValid;
            } catch (error) {
                setIsValidMsisdn(false);
                setForwardMsisdnWorkingDraft('');
                return false;
            }
        } else {
            try {
                const isValid = isValidPhoneNumber(val);
                setIsValidMsisdn(isValid);
                setForwardMsisdnNotWorkingDraft(val.slice(1));
                return isValid;
            } catch (error) {
                setIsValidMsisdn(false);
                setForwardMsisdnNotWorkingDraft('');
                return false;
            }
        }

    }
    const getContent = (type: string) => {
        return <ForwardCall
            msisdn={type == 'working' ? formData.workingForwardMsisdn : formData.nonWorkingForwardMsisdn} type={type}
            isValid={checkIfIsValidPhoneNumber}></ForwardCall>
    }

    const handleWorkingHoursForwardCall = (res: boolean) => {
        if (res) {
            if (forwardMsisdnWorkingDraft.length == 0) {
                setFormData(prevState =>
                    ({...prevState,
                        workingForwardEnabled: false,
                        workingForwardMsisdn: null}));
                return;
            }
            setFormData(prevState =>
                ({...prevState,
                    workingForwardEnabled: true,
                    workingForwardMsisdn: Number(forwardMsisdnWorkingDraft),
                    workingWelcomeMessageEnabled: false
                }));
        }
    }
    const handleNotWorkingHoursForwardCall = (res: boolean) => {
        if (res) {
            if (forwardMsisdnNotWorkingDraft.length == 0) {
                setFormData(prevState =>
                    ({...prevState,
                        nonWorkingForwardEnabled: false,
                        nonWorkingForwardMsisdn: null

                    }));
                return;
            }
            setFormData(prevState =>
                ({
                    ...prevState,
                    nonWorkingForwardEnabled: true,
                    nonWorkingForwardMsisdn: Number(forwardMsisdnNotWorkingDraft),
                    nonWorkingAfterHoursMessageEnabled: false,
                    nonWorkingDontAcceptCalls: false
                }));
        }
    }


    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const data = {
            ...formData,
            workingForwardMsisdn: formData.workingForwardMsisdn ? Number(formData.workingForwardMsisdn) : null,
            nonWorkingForwardMsisdn: formData.nonWorkingForwardMsisdn ? Number(formData.nonWorkingForwardMsisdn) : null,
        }
        await dispatch(updateMsisdnSettingsCallRules({id, data}));
        navigate('/phoneNumbers');
    };

    const handleWelcomeMessageWorkingSubmit = (res: boolean) => {
        if (res) {
            setFormData(prevState =>
                ({
                    ...prevState,
                    workingWelcomeMessageEnabled: true,
                    workingWelcomeMessageOriginalName: tempFile.originalFileName,
                    workingWelcomeMessageBucketName: tempFile.bucketFileName,
                    workingForwardEnabled: false,

                }));

            setAudioURL(prevState => ({...prevState, workingWelcomeMessageUrl: tempFile.audioURL}));
        }

    }

    const handleAfterHoursMessageSubmit = (res: boolean) => {
        if (res) {
            setFormData(prevState =>
                ({
                    ...prevState,
                    nonWorkingAfterHoursMessageEnabled: true,
                    nonWorkingAfterHoursMessageOriginalName: tempFile.originalFileName,
                    nonWorkingAfterHoursMessageBucketName: tempFile.bucketFileName,
                    nonWorkingForwardEnabled: false,
                    nonWorkingDontAcceptCalls: false
                }));
            setAudioURL(prevState => ({...prevState, nonWorkingAfterHoursMessageUrl: tempFile.audioURL}));
        }

    }

    return (
        <>
            {formData &&
                <div className='w-full'>

        <div className={`${isMobile ? ' px-4' : isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>
        <div className={`flex flex-col border border-black gap-6  ${isMobile ? ' my-6' : ' m-8'} ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
        <div className={`border-b ${theme === 'dark' ? ' border-white' : ' border-black'}`} >

                                <div className='font-semibold px-6 pt-6 text-lg'>
                                    Call rules
                                </div>
                                <div className='text-grey-500 px-6 pb-6 text-sm'>
                                    The incoming call will be displayed to users assigned to the number.
                                </div>
                            </div>
                            <div className='flex justify-center'>
                                <div
                                    className={`border  flex items-center border-dotted justify-center w-[200px] py-4 ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                                    <Icon iconName='call_received'></Icon> Incoming calls
                                </div>
                            </div>
                            <div className='flex  text-sm'>
                                <div className='flex-col m-6 justify-center w-1/2'>
                                    <div>
                                        <div className='text-center mb-6 font-semibold'>
                                            What happens when you work?
                                        </div>
                                        <div>{welcomeMessageTrigger}</div>
                                        <div className={'hidden'}>
                                            <ChModal
                                                ref={welcomeMessageWorkingRef}
                                                callback={handleWelcomeMessageWorkingSubmit}
                                                title={'Create a welcome message'}
                                                content={<WelcomeMessage welcomeMessageWorkingRef={welcomeMessageWorkingRef}
                                                />
                                                }
                                            />
                                        </div>

                                    </div>
                                    {forwardCallWorkingTrigger}
                                    {receiveIncomingCallTrigger}
                                    <ChModal callback={handleWorkingHoursForwardCall}
                                             title={'Forward call'}
                                             isValid={isValidMsisdn}
                                             content={getContent('working')}
                                             secondaryLabel='Cancel'
                                             primaryLabel='Confirm'
                                             ref={forwardCallWorkingRef}

                                    />


                                </div>
                                <div className='flex-col m-6 justify-center  w-1/2 '>
                                    <div className='text-center mb-6 font-semibold'>

                                        What happens when you don't work?
                                    </div>
                                    {afterHoursMessageTrigger}
                                    <ChModal
                                        ref={afterHoursMessageRef}
                                        callback={handleAfterHoursMessageSubmit}
                                        title={'Create a after hours message'}
                                        content={<WelcomeMessage welcomeMessageWorkingRef={afterHoursMessageRef}
                                        />
                                        }
                                    />
                                    {forwardCallNonWorkingTrigger}
                                    <ChModal callback={handleNotWorkingHoursForwardCall} title={'Forward call'}
                                             isValid={isValidMsisdn}
                                             content={getContent('noWorking')} secondaryLabel='Cancel'
                                             primaryLabel='Confirm'
                                             ref={forwardCallNonWorkingRef}

                                    />
                                    {dontAcceptCallsElement}



                                </div>
                            </div>
                        </div>
                        <div className={`mx-8 mt-8 flex justify-end`}>
                            <div className='flex gap-3 justify-end m-8'>
                                <Link to={'/phoneNumbers'}><Button buttonType='secondary' size='large'
                                                                   label={'Cancel'}></Button></Link>
                                <Button size='large' onClick={(e) => handleSubmit(e)} label={'Done'}></Button>
                            </div>
                        </div>
                    </div>
                </div>

            }
        </>
    );
}
export default CallRules;
