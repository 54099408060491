import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {ColorKey} from "../../../utilis/types/Types";
import axios from 'axios';
import {filterParams} from "../calls/callsSlice";
import {getApiUrl} from "../../../config";

export interface User {
    userId: number;
    email: string;
    firstName: string;
    lastName: string;
    status: UserStatus;
    iconColor: ColorKey;
    createdAt: string;

}

interface UsersState {
    currentUser: any;
    users: User[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}
const initialUser = {
    userId: 0,
    email: "",
    firstName: "",
    lastName: "",
    status: {
        userId: 0,
        customUserStatus: {
            userId: 0,
            name: "",
            icon: "",
            dueDate: ""
        },
        telcoUserStatus: {
            status: "UNKNOWN",
            lastUpdated: null
        },
        doNotDisturbUserStatus: {
            userId: 0,
            enabled: false,
            lastUpdatedAt: ""
        }
    },
    iconColor: null,
    createdAt: ""
};
const initialState: UsersState = {
    currentUser: initialUser,
    users: [],
    status: 'idle',
    error: null,
};


type CustomUserStatus = {
    userId: number;
    name: string;
    icon: string;
    dueDate: string;
};

type TelcoUserStatus = {
    status: string;
    lastUpdated: string | null;
};

type DoNotDisturbUserStatus = {
    userId: number;
    enabled: boolean;
    lastUpdatedAt: string;
};

type UserStatus = {
    userId: number;
    customUserStatus: CustomUserStatus;
    telcoUserStatus: TelcoUserStatus;
    doNotDisturbUserStatus: DoNotDisturbUserStatus;
};


const getHeaders = () => ({
    'Content-Type': 'application/json',
    'tenant': localStorage.getItem('tenant'),
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
});
export const fetchCurrentUser = createAsyncThunk('users/fetchCurrentUser', async () => {
    try {

        const userId = localStorage.getItem('userId');
        if (!userId) {
            throw new Error('User ID not found in local storage');
        }

        const userResponse =
           await axios.get(`${await getApiUrl()}/user/${userId}`, { headers: getHeaders() });

        const statusesResponse = await axios.get(`${await getApiUrl()}/statuses`, {
            headers: getHeaders(),
            params: { users: userId },
        });

        return {
            ...userResponse.data,
            status: statusesResponse.data.statuses[0],
        };
    } catch (error) {
        console.error('Error fetching current user:', error);
        throw error; // Ensure the error is propagated properly
    }
});
// Fetch all users
export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
    const response = await axios.get(`${await getApiUrl()}/user/filter`, { headers: getHeaders() });
    return response.data;
});

// Create user
export const createUser = createAsyncThunk('users/createUser', async (newUser: User) => {
    const response = await axios.post(`${await getApiUrl()}/user`, newUser, { headers: getHeaders() });
    return response.data;
});

// Update user
export const updateUser = createAsyncThunk('users/updateUser', async (updatedUser: User) => {
    const response = await axios.patch(`${await getApiUrl()}/user/${updatedUser.userId}`, updatedUser, { headers: getHeaders() });
    return response.data;
});

// Delete user
export const deleteUser = createAsyncThunk('users/deleteUser', async (userId: number) => {
    await axios.delete(`${await getApiUrl()}/user/${userId}`, { headers: getHeaders() });
    return userId;
});
export const searchUsers = createAsyncThunk('users/searchUsers', async (phrase: string) => {
    const response = await axios.post(`${await getApiUrl()}/user/search`,
        {
            headers: getHeaders(),
            params: filterParams({ phrase }),
        });
    return response.data;
});

export const toogleDnd = createAsyncThunk<any, any, { rejectValue: string }>(
    'status/toogleDnd',
    async (enabled, thunkAPI) => {
        const userId = localStorage.getItem('userId');
        try {
            const response = await axios.patch(`${await getApiUrl()}/statuses/user/${userId}/dnd`, {enabled}, {
                headers: getHeaders(),
            });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return thunkAPI.rejectWithValue(error.response?.data);
            } else {
                throw error;
            }
        }
    }
);

// Create slice
const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.users = action.payload;
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || null;
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.users.push(action.payload);
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                const updatedUser = action.payload;
                const existingUser = state.users.find((user) => user.userId === updatedUser.userId);
                if (existingUser) {
                    Object.assign(existingUser, updatedUser);
                }
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                const userId = action.payload;
                state.users = state.users.filter((user) => user.userId !== userId);
            })
            .addCase(searchUsers.fulfilled, (state, action) => {
                state.users = action.payload;
            })
            .addCase(fetchCurrentUser.fulfilled, (state, action) => {
            state.currentUser = action.payload;
             })
            .addCase(toogleDnd.fulfilled, (state, action) => {
                state.currentUser.status.doNotDisturbUserStatus.enabled = action.payload.enabled;
            })
    },
});

export default usersSlice.reducer;
