export const validateEmail = (email: string | null): boolean => {
    if (!email) return false;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
}
export const validatePhoneNumber = (phoneNumber: string): boolean => {
    const phoneNumberRegex =/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
    return phoneNumberRegex.test(phoneNumber);
}
export const validateName = (name: string) => {
    return Boolean(name) && name.trim().length > 0;
};
