import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from 'axios';
import {RootState} from "../../store";
import {FetchGroupsResponse} from "../groups/groupsSlice";
import {filterParams} from "../calls/callsSlice";
import {UsersParamsProps} from "../Searches/searchSlice";
import {getApiUrl} from "../../../config";

export enum TelcoStatus {
    ONLINE= 'ONLINE',
    TALKING = 'TALKING',
    OFFLINE = 'OFFLINE',
    UNKNOWN = 'UNKNOWN',
    DND = 'DND'
}

const getHeaders = () => ({
    'Content-Type': 'application/json',
    'tenant': localStorage.getItem('tenant'),
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
});

export const getStatuses = createAsyncThunk<any, any, { rejectValue: string }>(
    'status/getStatuses',
    async ({users}, thunkAPI) => {
        try {
            const response = await axios.get(`${await getApiUrl()}/statuses`, {
                headers: getHeaders(),
                params: filterParams({users}),
                paramsSerializer: params => {
                    return Object.entries(params).map(([key, value]) => {
                        if (Array.isArray(value)) {
                            if (value.length === 0) return;
                            return `${key}=${value.join(',')}`;
                        }
                        return `${key}=${value}`;
                    }).join('&');
                }
            });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return thunkAPI.rejectWithValue(error.response?.data);
            } else {
                throw error;
            }
        }
    }
);

export const changeStatus = createAsyncThunk<any, any, { rejectValue: string }>(
    'status/changeStatus',
    async (data, thunkAPI) => {
        const userId = localStorage.getItem('userId');

        try {
            const response = await axios.patch(`${await getApiUrl()}/statuses/user/${userId}/custom`, data, {
                headers: getHeaders(),
            });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return thunkAPI.rejectWithValue(error.response?.data);
            } else {
                throw error;
            }
        }
    }
);


export const fetchUsers = createAsyncThunk<FetchGroupsResponse[], UsersParamsProps>('search/fetchUsers', async ({
                                                                                                                    phrase,
                                                                                                                    lastUserId,
                                                                                                                    lastLastName,
                                                                                                                    lastFirstName,
                                                                                                                    sortBy,
                                                                                                                    order
                                                                                                                }) => {
    try {
        const response = await axios.get(`${await getApiUrl()}/user/filter`, {
            headers: getHeaders(),
            params: filterParams({phrase, lastUserId, lastLastName,lastFirstName, sortBy, order}),
            paramsSerializer: params => {
                return Object.entries(params).map(([key, value]) => {
                    if (Array.isArray(value)) {
                        if (value.length === 0) return;
                        return `${key}=${value.join(',')}`;
                    }
                    return `${key}=${value}`;
                }).join('&');
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
});

interface CustomUserStatus {
    userId: string;
    name: string;
    icon: string;
    dueDate: string;
}

interface TelcoUserStatus {
    status: TelcoStatus;
    lastUpdated: string;
}

interface DoNotDisturbUserStatus {
    userId: string;
    enabled: boolean;
    lastUpdatedAt: string;
}

export interface UserStatus {
    userId: number;
    firstName: string;
    lastName: string;
    customUserStatus: CustomUserStatus[];
    telcoUserStatus: TelcoUserStatus;
    doNotDisturbUserStatus: DoNotDisturbUserStatus;
}

interface StatusState {
    statuses: UserStatus[];
    loading: boolean;
    error: string | null;
}

const initialState: StatusState = {
    statuses: [],
    loading: false,
    error: null,
};

const teamSlice = createSlice({
    name: 'status',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getStatuses.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getStatuses.fulfilled, (state, action: PayloadAction<any>) => {
                state.statuses = action.payload.statuses;
                state.loading = false;
            })
            .addCase(getStatuses.rejected, (state, action: PayloadAction<string | undefined>) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch statuses';
            })
    }
});

export const teamStatusesSelector = (state: RootState) =>
    state.team.statuses;

export default teamSlice.reducer;
