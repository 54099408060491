import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CallControls from "./components/CallControls";
import Keypad from "./components/Keypad";
import Call from "./Call";
import {RootState, useAppDispatch} from "../../store/store";
import {AsYouType, isValidPhoneNumber} from 'libphonenumber-js/max';
import {parsePhoneNumber} from 'libphonenumber-js';
import {
    getAutoGainControl,
    getSelectedMicrophone, getSelectedSpeaker,
    selectSelectedPhoneNumber, setCallFrom,
    setCallState, setPhoneNumber,
    setSelectedPhoneNumber, setSelectedPrefix
} from "../../store/features/calls/dialerSlice";
import { getUserMsisdns } from "../../store/features/Msisdns/msisdnsSlice";
import { initializeSip, call as makeCall, answerCall as acceptCall, rejectCall, endCall } from './services/sipService';
import {CallInput} from "./components/CallInput";
import {useCallOptions} from "./hooks/useCallOptions";
import {CountryCode} from "libphonenumber-js/max";

export enum CallStateEnum {
    CONNECTING = 'CONNECTING',
    IDLE = 'IDLE',
    IN_CALL = 'IN_CALL',
}

interface DialerProps {
    isInCalls: boolean;
}

function Dialer({ isInCalls }: DialerProps) {
    const dispatch = useAppDispatch();


    const [timer, setTimer] = useState(0);
    const [error, setError] = useState<string>('');
    const selectedPhoneNumber = useSelector(selectSelectedPhoneNumber);
    const availablePhoneNumbers = useSelector((state: RootState) => state.msisdns.msisdns);
    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);
    const incomingCall = useSelector((state: RootState) => state.dialer.incomingCall);
    const callFrom = useSelector((state: RootState) => state.dialer.callFrom);
    const callState = useSelector((state: RootState) => state.dialer.callState);
    const selectedMicrophone = useSelector(getSelectedMicrophone);
    const autoGainControl = useSelector(getAutoGainControl);

    const phoneNumber = useSelector((state: RootState) => state.dialer.phoneNumber);
    const audioDevice = useSelector(getSelectedSpeaker);
    const selectedPrefix = useSelector((state: RootState) => state.dialer.selectedPrefix);

    const [typedNumber, setTypedNumber] = useState('');

    const options = useCallOptions();

    useEffect(() => {
        askNotificationPermission();
        initializeSip(dispatch, audioDevice);
    }, [dispatch]);

    useEffect(() => {
        const fetchUserNumbers = async () => {
            await dispatch(getUserMsisdns());
        };
        if (!selectedPhoneNumber) {
            fetchUserNumbers().then(() => {
                dispatch(setSelectedPhoneNumber(availablePhoneNumbers[0]));
            });
        }
    }, [selectedPhoneNumber, availablePhoneNumbers, dispatch]);


    useEffect(() => {
        if (phoneNumber.startsWith('+')) {
            try {
                const phoneNumberParsed = parsePhoneNumber(phoneNumber);

                if (phoneNumberParsed) {
                    const {country, countryCallingCode} = phoneNumberParsed;

                    if (countryCallingCode) {
                        dispatch(
                            setSelectedPrefix({
                                isoCountry: country ?? 'US',
                                prefix: `+${countryCallingCode}`,
                            }),
                        );
                    }
                }
            } catch (e) {
                // ignore as it is not yet a proper number with country code
            }
        }
    }, [typedNumber, dispatch]);

    useEffect(() => {
        let interval: number;
        if (callState === CallStateEnum.IN_CALL) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer + 1);
            }, 1000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
                setTimer(0);
            }
        };
    }, [callState]);

    const isPhoneValid = (phone: string) => {
        try {

            return  isValidPhoneNumber(phone);

        } catch (error) {
            return false;
        }
    };

    const handleCall = () => {
        console.log(phoneNumber);
        let number = phoneNumber;
        if(!phoneNumber.toString().includes('+'))
        {
                 number = selectedPrefix.prefix + phoneNumber.toString();
        }
        if (!isPhoneValid(number)) {
            setError('Invalid phone number');
            return;
        }
        dispatch(setCallState(CallStateEnum.CONNECTING));
        dispatch(setCallFrom(number));
        setError('');


        makeCall(number, options, audioDevice);
    };
    const keypadClicked = (key: string) => {
        const newPhoneNumber = phoneNumber + key;
        dispatch(setPhoneNumber(newPhoneNumber));
        setTypedNumber(
            new AsYouType(selectedPrefix.isoCountry as CountryCode).input(newPhoneNumber),
        );
    };
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (document.activeElement && document.activeElement.tagName === 'INPUT' && document.activeElement.getAttribute('name') !== 'dialer') {
                return;
            }
            if (event.key === 'Backspace') {
                const updatedNumber = phoneNumber.slice(0, -1);
                dispatch(setPhoneNumber(updatedNumber));
                return;
            }
            if (event.key === 'Enter') {
                handleCall();
                return;
            }
            const key = event.key;
            if (/^[0-9+]$/.test(key)) {
                const newPhoneNumber = phoneNumber + key;
                dispatch(setPhoneNumber(newPhoneNumber));
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [phoneNumber, selectedPrefix.isoCountry, dispatch]);



    const askNotificationPermission = () => {
        // Check if the browser supports notifications
        if (!("Notification" in window)) {
            console.log("This browser does not support notifications.");
            return;
        }
        Notification.requestPermission().then((permission) => {
            console.log('Permission', permission);
        });
    }

    return (
        <div className={`fixed right-0 top-0 mt-[162px] h-[calc(100vh-162px)] z-10 ${isDialerHidden ? 'w-[80px]' : 'w-[320px]'}`}>
            {callState === CallStateEnum.IDLE && !incomingCall && (
                <>
                    <div className="flex gap-4 justify-center px-12 py-6 text-sm leading-5 text-neutral-500b">
                        {Boolean(!isDialerHidden) && (
                            <CallInput
                                phoneNumber={phoneNumber}
                                error={error}
                               />
                        )}
                    </div>
                    {Boolean(!isDialerHidden) && (
                        <Keypad keypadClicked={keypadClicked} />
                    )}
                </>
            )}
            <div>
                <Call isDialerHidden={isDialerHidden} callFrom={callFrom} phoneNumber={phoneNumber} timer={timer} isIncomingCall={incomingCall} callState={callState} />
            </div>
            <CallControls
                callState={callState}
                isIncomingCall={incomingCall}
                call={handleCall}
                rejectCall={() => rejectCall(dispatch)}
                answerCall={() => acceptCall(dispatch, {
                    mediaConstraints: {
                        audio: {
                            autoGainControl: autoGainControl,
                            ...(selectedMicrophone ? { deviceId: selectedMicrophone } : {})
                        },
                        video: false
                    }

                }  , audioDevice)}
                endCall={() => endCall(dispatch)}
                isInCalls={isInCalls}
                removeChar={() =>  dispatch(setPhoneNumber(phoneNumber.slice(0, -1)))}
            />
        </div>
    );
}

export default Dialer;
