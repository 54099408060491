import {Link} from "react-router-dom";
import {Avatar, Button, ChInput, ChModal} from "ch3-ui-lib";
import {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {getMsisdnSettingsInfo, updateMsisdnSettingsInfo} from "../../store/features/MsisdnSettings/msisdnSettingsSlice";
import {EmojiStyle} from "emoji-picker-react";
import EmojiPicker from "emoji-picker-react";
import {User} from "../../store/features/Users/usersSlice";
import {Group} from "../../store/features/groups/groupsSlice";
import AddContactModal from "../Contacts/AddContactModal";
import {Colors} from "../../utilis/types/Types";
import {validateEmail, validateName} from "../../utilis/commonValidators";
import {useNavigate} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import { useTheme } from "next-themes";
import {ClosedDialerMargin, OpenDialerMargin} from "../../utilis/dialerWidthUtils.ts";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import {hexToEmoji} from "../../components/Emoji/emoji";


export const PhoneNumberInfo = () => {

    const {id} = useParams<{ id: string }>();
    const isMobile = useMediaQuery('(max-width: 960px)');

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);

    const msisdnInfo = useSelector((state: RootState) => state.msisdnSettings.info);

    const { theme } = useTheme();

    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);


    const [users, setUsers] = useState([] as User[]);
    const [groups, setGroups] = useState([] as Group[])
    const pickerRef = useRef<HTMLDivElement>(null);
    const [formData, setFormData] = useState({

        msisdn: {
            label: msisdnInfo?.msisdn?.label ?? '',
            icon: msisdnInfo?.msisdn?.icon ?? '1F4DE',
            msisdn: msisdnInfo?.msisdn?.msisdn ?? 0,
            msisdnId: msisdnInfo?.msisdn?.msisdnId ?? 0,
            type: msisdnInfo?.msisdn?.type ?? '',
        },
        emailsSetting: {
            enabled: msisdnInfo?.emailsSetting?.enabled ?? false,
            email:  msisdnInfo?.emailsSetting?.email ?? null
        },
        // cname: '',
        callRecordingEnabled: true,
        users: msisdnInfo?.users?.length ? [...msisdnInfo?.users] : [],
        groups: msisdnInfo?.groups?.length ? [...msisdnInfo?.groups] : [],
    })
    const [errors, setErrors] = useState({
        name: '',
        icon: '',
        email: '',
    });

    const handleChange = (field:string, value: string) => {
        const newErrors = { ...errors };

        if (field === 'label') {
            newErrors.name = validateName(value) ? '' : 'Name is required';
        }

        if (field === 'icon') {
            newErrors.icon = validateName(value) ? '' : 'Icon is required';
        }

        if (field === 'email') {
            newErrors.email = formData.emailsSetting.enabled ? validateEmail(value) ? '' : 'Email is required' : '';
        }

        setErrors(newErrors);
        setFormData((prev) => ({...prev, msisdn: {...prev.msisdn, label: value}}));
    }
    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getMsisdnSettingsInfo(id));
        }
        fetchData();
    }, [id]);
    useEffect(() => {
        setFormData({
            msisdn: {
                label: msisdnInfo?.msisdn?.label ?? '',
                icon: msisdnInfo?.msisdn?.icon ?? '1F4DE',
                msisdn: msisdnInfo?.msisdn?.msisdn ?? 0,
                msisdnId: msisdnInfo?.msisdn?.msisdnId ?? 0,
                type: msisdnInfo?.msisdn?.type ?? '',
            },
            emailsSetting: {
                enabled: msisdnInfo?.emailsSetting?.enabled ?? false,
                email: msisdnInfo?.emailsSetting?.email ?? null
            },
            callRecordingEnabled: true,
            users: msisdnInfo?.users?.length ? [...msisdnInfo?.users] : [],
            groups: msisdnInfo?.groups?.length ? [...msisdnInfo?.groups] : [],
        })
        setGroups(msisdnInfo?.groups ?? []);
        setUsers(msisdnInfo?.users ?? []);
    }, [msisdnInfo]);

    const handleValidation = () => {
        const newErrors = {
            name: validateName(formData.msisdn.label) ? '' : 'Name is required',
            icon: validateName(formData.msisdn.icon) ? '' : 'Icon is required',
            email: formData.emailsSetting.enabled ? validateEmail(formData.emailsSetting.email) ? '' : 'Email is required' : '',
        };
        setErrors(newErrors);
        return newErrors;
    };

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (!formData.emailsSetting.enabled && formData.emailsSetting.email == '') {
            setFormData((prev) => ({...prev, emailsSetting: {...prev.emailsSetting, email: null}}));
        }
        const fieldErrors = Object.values(handleValidation());
        const hasFieldErrors = fieldErrors.some(fieldError => {
            if (Array.isArray(fieldError)) {
                return fieldError.some(error => error !== '');
            } else {
                return fieldError !== '';
            }
        });

        if (hasFieldErrors) return;
        const data = {
            ...formData,
            users: formData.users.map((user) => user.userId),
            groups: formData.groups.map((group) => group.id),
        }

        await dispatch(updateMsisdnSettingsInfo({id, data}));
        navigate('/phoneNumbers');
    };
    // const handleEmailChange = (val: string) => {
    //     setFormData((prev) => ({...prev, emailsSetting: {...prev.emailsSetting, email: val}}));
    // }
    const openEmojiPicker = (e: any) => {
        setEmojiPickerOpen(!emojiPickerOpen);
        e.stopPropagation();

    }

    const handleClickOutside = (event: any) => {
        if (pickerRef.current && !pickerRef.current.contains(event.target as Node)) {
            setEmojiPickerOpen(false);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [emojiPickerOpen]);

    const handleMenageAccessChange = (res: boolean) => {

        if (!res) {
            setUsers([...formData.users]);
            setGroups([...formData.groups]);
        }

        const data = {
            ...formData,
            users: users,
            groups: groups,
        };

        setFormData(data);
    };
    const addContactTrigger = <Button buttonType='secondary'
                                      label='Add a team member' rightIcon='add'/>;
    const menageAccessTrigger = <Button buttonType='secondary' label='Share access' size='small' leftIcon='settings'/>
    const modalContent = <AddContactModal selectedUsers={formData.users}
                                          selectedGroups={formData.groups} setUsers={setUsers}
                                          setGroups={setGroups}/>

    const handlePickEmote = (val:any) => {
        console.log(val);
        setFormData((prev) => ({...prev, msisdn: {...prev.msisdn, icon: val.unified}}));
        setEmojiPickerOpen(false);
        setErrors({...errors, icon: ''})
    }

    //
    // const handleEmailNotificationChange = () => {
    //
    //     setFormData((prev) => ({...prev, emailsSetting: {...prev.emailsSetting, enabled: !prev.emailsSetting.enabled}}));
    // }
    // const handleCallRecordingChange = (val: boolean) => {
    //     setFormData((prev) => ({...prev, callRecordingEnabled: val}));
    // }
    return (
      <>{ msisdnInfo && !isMobile &&
        <div className={`${isMobile ? ' px-4' : isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>
              <div className={`flex flex-col border border-black gap-6  ${isMobile ? ' my-6' : ' m-8'} ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                  <div className='border-b border-black'>

                      <div className='font-semibold px-6 pt-6 text-lg'>
                          General information
                      </div>
                      <div className='text-grey-500 px-6 pb-6 text-sm'>
                          Update name and general information
                      </div>
                  </div>
                  <div className='px-6 gap-6 grid'>
                      <div className={'block'}>
                          <div className={'flex mb-6 gap-2 items-end'}>
                              <div className={`cursor-pointer items-center flex mb-2 border-b h-[49px] w-[56px] justify-center items-cente= text-2xl ${errors.icon && ' !border-error-500'} ${theme === 'dark' ? ' border-white' : ' border-black'}`}
                                   onClick={(event) => openEmojiPicker(event)}>
                                  <div className='mx-' >
                                      {hexToEmoji(formData.msisdn.icon)}
                                  </div>

                              </div>
                              <div>
                                  <ChInput value={formData.msisdn.label}
                                           callback={(val: string) => {
                                               handleChange('label', val);
                                           }} error={errors.name}
                                           className='max-w-full' label='Name'/>
                              </div>
                              <div ref={pickerRef} className={'fixed'}>
                                  <EmojiPicker emojiStyle={EmojiStyle['TWITTER']} onEmojiClick={(val) => handlePickEmote(val)} open={emojiPickerOpen}/>
                              </div>
                          </div>
                      </div>


                      {/*<div>*/}
                      {/*    <ChInput value={formData.cname} callback={(val: string) => handleChange(val, 'lastName')}*/}
                      {/*             error={errors.cname} className='max-w-full' label='Cname'/>*/}
                      {/*</div>*/}

                  </div>
              </div>

              <div className={`flex flex-col border border-x-black border-t-black ${isMobile ? ' my-6' : ' m-8'}`}>


                  <div>

                      <div className='p-6 font-semibold text-lg flex justify-between'>
                          <div>
                          <div>
                              Share access
                          </div>
                          <div className='pr-6 text-grey-500 text-sm'>
                          Give other members in your workspace access to this phone number
                            </div>
                            </div>
                          {Boolean(formData.users?.length || formData.groups?.length) && <div>
                              <ChModal callback={handleMenageAccessChange} title={'Share access'}
                                       trigger={menageAccessTrigger} content={modalContent} secondaryLabel='Cancel'
                                       primaryLabel='Confirm'/>
                          </div>
                          }
                      </div>
                      {Boolean(!formData.users?.length && !formData.groups?.length) &&
                          <div className={`p-6 border-b border-t  flex justify-center ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                              <ChModal title={'Share access'} callback={handleMenageAccessChange}
                                       trigger={addContactTrigger} content={modalContent} secondaryLabel='Cancel'
                                       primaryLabel='Confirm'/>
                          </div>}

                      {Boolean(formData.users?.length) && <>
                          <div className={`p-6 border-b border-t ${theme === 'dark' ? ' border-white' : ' border-black'}`} >
                              Users
                          </div>
                          <div>

                              {(formData.users?.map((user, index) => {
                                  return (
                                      <div className={`border-b ${theme === 'dark' ? ' border-white' : ' border-black'}`}  w-full key={index}>
                                          <div className='flex gap-3  items-center pl-4 py-4'>
                                              <Avatar size="md" type='initials'
                                                      color={user.iconColor ?? Colors.orange}
                                                      name={user.firstName + ' ' + user.lastName}/>{user.firstName + ' ' + user.lastName}
                                          </div>
                                      </div>
                                  )
                              }))
                              }
                          </div>

                      </>}
                  </div>
                  {Boolean(formData.groups?.length) && <>
                      <div className='p-6 border-b border-t border-black'>
                          Groups
                      </div>
                      <div>
                          {formData.groups.map((group, index) => {
                                  return (
                                      <div className='border-b border-black w-full' key={index}>
                                          <div className='flex gap-3  items-center pl-4 py-4'>
                                              <Avatar size="md" type='icon'
                                                      color={group.iconColor ?? Colors.orange}
                                                      icon='home'/>{group.name}
                                          </div>
                                      </div>
                                  )
                              }
                          )}

                      </div>
                  </>
                  }
              </div>
              <div className='mr-[384px] w-full'>
                  {/*<div className="flex flex-col border border-black  m-8 gap-6  ">*/}
                  {/*    <div className='border-b border-black'>*/}
                  {/*        <div className='font-semibold px-6 pt-6 text-lg'>*/}
                  {/*            Notification*/}
                  {/*        </div>*/}
                  {/*        <div className='text-grey-500 px-6 pb-6 text-sm'>*/}
                  {/*            Set notification on this phone number*/}
                  {/*        </div>*/}
                  {/*    </div>*/}
                  {/*    <div className='mx-6 py-6 border-b border-gray-900'>*/}
                  {/*        <Switch size='lg'*/}
                  {/*                checked={formData.emailsSetting.enabled}*/}
                  {/*                callback={handleEmailNotificationChange}*/}
                  {/*                label={'Missed call'}*/}
                  {/*                description={'Set where you want to receive notifications about missed calls'}/>*/}
                  {/*        { formData.emailsSetting.enabled &&*/}
                  {/*            <div className='flex gap-3 mx-6 py-6 '>*/}
                  {/*                <ChInput value={formData.emailsSetting.email ?? undefined}*/}
                  {/*                         callback={(val: string) => handleEmailChange(val)}*/}
                  {/*                         error={errors.email}*/}
                  {/*                         className='max-w-full' label='Mails'/>*/}

                  {/*            </div>}*/}
                  {/*    </div>*/}
                  {/*    <div className='m-6'>*/}
                  {/*        /!*<Switch size='lg'*!/*/}
                  {/*        /!*        checked={formData.callRecordingEnabled}*!/*/}
                  {/*        /!*        callback={handleCallRecordingChange}*!/*/}
                  {/*        /!*        label={'Call recording'}*!/*/}
                  {/*        /!*        description={'With this option on, every call you take and make will be recorded and stored accordingly to your subscription.'}/>*!/*/}
                  {/*    </div>*/}
                  {/*</div>*/}
              </div>
              <div className={`mx-8 mt-8 flex justify-end`}>
                  <div className='flex gap-3 justify-end m-8'>
                      <Link to={'/phoneNumbers'}><Button buttonType='secondary' size='large'
                                                         label={'Cancel'}></Button></Link>
                      <Button size='large' onClick={(e) => handleSubmit(e)} label={'Done'}></Button>
                  </div>
              </div>
          </div>
      }
     </>
    )
}
